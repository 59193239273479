





























import { Component, Vue } from 'vue-property-decorator';
import HeaderPanel from '@/themes/v1/layouts/HeaderPanel.vue';
import DrawerMenu from '@/themes/v1/layouts/DrawerMenu.vue';
import Box from '@/themes/v1/components/Box/Box.vue';
import Footer from '@/themes/v1/layouts/Footer.vue';
import BoxContent from '@/themes/v1/components/Box/BoxContent.vue';
import SectionCircleLink from '@/themes/v1/components/SectionCircleLink.vue';
import FaqAndGuideLinks from '@/themes/v1/components/FaqAndGuideLinks.vue';
import { SystemStore } from '@/core/system';
import { getModule } from 'vuex-module-decorators';

@Component({
  components: {
    FaqAndGuideLinks,
    SectionCircleLink,
    BoxContent,
    Box,
    DrawerMenu,
    HeaderPanel,
    Footer,
  },
})
export default class UnderMaintenancePage extends Vue {
  systemStore = getModule(SystemStore, this.$store);
  code = 502;
}
