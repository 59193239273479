import { AxiosInstance } from 'axios';
import env from '@/env';
import { LanguageCode } from '@/core/system';
import {
  AllowUnsubscribeStatus,
  SubscribingResult,
  TemporaryProblemViewModel,
} from '@/modules/sts/temporary-problems/temporary-problems.model';

export class TemporaryProblemsService {
  constructor(private readonly $http: AxiosInstance) {}

  async getList(
    language: LanguageCode,
    platform: string
  ): Promise<TemporaryProblemViewModel[]> {
    const { data } = await this.$http.get<TemporaryProblemViewModel[]>(
      `/tickets/temporary-problems`,
      {
        params: { game: env.ticketsGame, language, platform },
      }
    );
    return data;
  }

  async getSingle(
    language: LanguageCode,
    problemId: string
  ): Promise<TemporaryProblemViewModel | null> {
    const { data } = await this.$http.get<TemporaryProblemViewModel>(
      `/tickets/temporary-problems/${problemId}`,
      {
        params: { game: env.ticketsGame, language },
      }
    );
    if ((data as any) == '') {
      return null;
    }
    return data;
  }

  async subscribe(
    language: LanguageCode,
    problemId: TemporaryProblemViewModel['id'],
    email: string,
    playerId: string,
    loginId: string,
    platform: string
  ) {
    const { data } = await this.$http.post<SubscribingResult>(
      `/tickets/temporary-problems`,
      {
        game: env.ticketsGame,
        problemId,
        email,
        language,
        playerId,
        loginId,
        platform,
      }
    );
    return data;
  }

  async checkUnsubscribe(
    problemId: TemporaryProblemViewModel['id'],
    email: string
  ): Promise<AllowUnsubscribeStatus> {
    const { data } = await this.$http.post<AllowUnsubscribeStatus>(
      `/tickets/temporary-problems/unsubscribe-status`,
      {
        game: env.ticketsGame,
        problemId,
        email,
      }
    );

    return data;
  }

  async unsubscribe(
    problemId: TemporaryProblemViewModel['id'],
    subscription: string
  ) {
    await this.$http.post(`/tickets/temporary-problems/unsubscribe`, {
      game: env.ticketsGame,
      problemId,
      subscription,
    });
  }

  async preview(previewId: string, language: string) {
    const result = await this.$http.get<TemporaryProblemViewModel>(
      `/tickets/temporary-problems/preview/${env.ticketsGame}/${previewId}/${language}`
    );
    return result.data;
  }
}
