












































import { Component, Inject, Vue } from 'vue-property-decorator';

import { GuideStore } from '@/themes/v1/stores/guide.store';
import NeedSomethingElse from '@/themes/v1/components/NeedSomethingElse.vue';
import SectionCircleLink from '@/themes/v1/components/SectionCircleLink.vue';
import HotNews from '@/themes/v1/components/HotNews.vue';
import { getModule } from 'vuex-module-decorators';
import { OnMounted, WithMeta } from '@/core/vue.types';
import { HotNewsPreview } from '@/modules/guide/articles';
import config from '@/env';
import hotNewsListBuilder from '@/themes/v1/fns/hot-news-list-builder';
import { AppStore } from '@/themes/v1/stores/app.store';
import { ArticleService } from '@/modules/guide/articles/article.service';
import CircleSectionGroup from '@/themes/v1/components/CircleSectionGroup.vue';
import { makeGroups } from '@/themes/v1/fns/array-utils';
import { SystemStore } from '@/core/system';
import { MetaInfo } from 'vue-meta';
import Multiline from '@/themes/v1/directives/multiline-text';

interface SectionItem {
  key: string;
  path: string;
  name: string;
}

@Component({
  components: {
    CircleSectionGroup,
    HotNews,
    SectionCircleLink,
    NeedSomethingElse,
  },
  directives: {
    Multiline,
  },
})
export default class Home extends Vue implements OnMounted, WithMeta {
  @Inject()
  articleService!: ArticleService;

  guideStore = getModule(GuideStore, this.$store);
  appStore = getModule(AppStore, this.$store);
  systemStore = getModule(SystemStore, this.$store);
  hotNews: HotNewsPreview[] = [];
  loading = false;
  newsFullCount = 0;

  async mounted(): Promise<void> {
    await this.loadHotNews();
  }

  async loadHotNews() {
    try {
      this.loading = true;
      const news = await this.articleService.getLastNews(
        config.game,
        this.$i18n.locale,
        'useful-articles',
        6
      );
      this.newsFullCount = news.length;
      const hotNews = this.systemStore.device.isMobileOrTablet
        ? hotNewsListBuilder(news, 3, 1)
        : hotNewsListBuilder(news, 2, 3);
      this.guideStore.setHotNews(hotNews);
    } finally {
      this.loading = false;
    }
  }

  onNewsItemClick(newsItemTitle: string) {
    this.$track('main_page', 'click_tab_useful', newsItemTitle);
  }

  get sections(): Array<SectionItem> {
    const sections: SectionItem[] = ['guide', 'faq', 'tech']
      .map((key) => this.appStore.sections[key])
      .filter(Boolean)
      .map((x) => ({
        key: x.pathSegment,
        path: this.$routerHelper.withPlatformAndLang(
          this.$route,
          x.pathSegment
        ),
        name: x.name,
      }));
    sections.push({
      key: 'ask',
      path: this.$routerHelper.withPlatformAndLang(this.$route, 'tickets'),
      name: this.$t('components.needHelp.title') as string,
    });
    return sections;
  }

  get groups(): Array<SectionItem[]> {
    return makeGroups<SectionItem>(this.sections, 2);
  }

  get showMore(): boolean {
    return this.newsFullCount > 3 && this.systemStore.device.isMobileOrTablet;
  }

  metaInfo(): MetaInfo {
    const meta = this.appStore.mainPageMetadata;

    const metaTags: any[] = [];
    if (meta != null) {
      if (meta.description) {
        metaTags.push({
          vmid: 'description',
          name: 'description',
          content: meta.description,
        });
      }
      if (meta.keywords) {
        metaTags.push({
          vmid: 'keywords',
          name: 'keywords',
          content: meta.keywords,
        });
      }
    }
    return {
      title: meta?.title,
      meta: metaTags,
    };
  }
}
