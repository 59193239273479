import { Module, VuexModule, Mutation } from 'vuex-module-decorators';
import { Dictionary } from 'vue-router/types/router';
import { normalizeLanguage } from '@/themes/v1/i18n/normalize-language';

function getQueryProperty(
  pairs: [string, string][],
  propertyName: string
): string | null {
  const prop = propertyName.toLocaleLowerCase();
  const pair = pairs.find((x) => x[0] === prop);
  if (pair != null) {
    const value = pair[1];
    try {
      return decodeURIComponent(value);
    } catch {
      return value;
    }
  }
  return null;
}

@Module({ name: 'player', stateFactory: true, namespaced: true })
export class PlayerStore extends VuexModule {
  playerId = '';
  deviceLocale = '';
  deviceModel = '';
  gameLocale = '';
  gameVersion = '';
  platformType = '';
  playerName = '';
  loginId = '';
  utcOffset = 0;
  timestamp = '';
  hash = '';
  email = '';
  advId = '';

  token = '';
  isAuth = false;
  wasAuthorized = false;

  @Mutation
  setAuth(state: boolean) {
    if (this.isAuth === true && state === false) {
      this.wasAuthorized = true;
    }
    this.isAuth = state;
  }

  @Mutation
  setPlayer(player: string) {
    this.playerId = player;
  }

  @Mutation
  setPlatform(platform: string) {
    this.platformType = platform;
  }

  @Mutation
  setToken(token: string) {
    this.token = token;
    this.isAuth = !!token;
  }

  @Mutation
  init(query: Dictionary<any>) {
    const dictPairs: [string, string][] = Object.entries(query).map((x) => [
      x[0].toLocaleLowerCase(),
      x[1],
    ]);
    this.playerId = getQueryProperty(dictPairs, 'playerId') || this.playerId;
    this.deviceLocale = normalizeLanguage(
      getQueryProperty(dictPairs, 'deviceLocale') || this.deviceLocale
    );
    this.deviceModel =
      getQueryProperty(dictPairs, 'deviceModel') || this.deviceModel;
    this.gameLocale = normalizeLanguage(
      getQueryProperty(dictPairs, 'gameLocale') || this.gameLocale
    );
    this.gameVersion =
      getQueryProperty(dictPairs, 'gameVersion') || this.gameVersion;
    this.platformType =
      getQueryProperty(dictPairs, 'platformType') || this.platformType;
    this.playerName =
      getQueryProperty(dictPairs, 'playerName') || this.playerName;
    this.loginId = getQueryProperty(dictPairs, 'loginId') || this.loginId;
    const utcOffset = getQueryProperty(dictPairs, 'utcOffset');
    this.utcOffset = utcOffset ? parseFloat(utcOffset) : this.utcOffset;
    this.advId = getQueryProperty(dictPairs, 'advId') || this.advId;
    this.timestamp = getQueryProperty(dictPairs, 'timestamp') || this.timestamp;
    this.hash = getQueryProperty(dictPairs, 'hash') || this.hash;
  }

  get hasAuthParams() {
    return !!(this.playerId && this.hash && this.timestamp);
  }

  get playerKeys() {
    return [
      'playerId',
      'deviceLocale',
      'deviceModel',
      'gameLocale',
      'gameVersion',
      'platformType',
      'playerName',
      'loginId',
      'advId',
    ];
  }

  get rawList() {
    return this.playerKeys.map((key) => `${key}=${this[key]}`);
  }
}
