export const timeout = (delay: number) =>
  new Promise((res) => {
    setTimeout(res, delay);
  });

export const humanizingTime = <T>(prom: Promise<T>): Promise<T> => {
  if (process.server) {
    return prom;
  }
  return new Promise<T>((res, rej) => {
    Promise.all([
      prom
        .then((result) => ({ success: 1, result }))
        .catch((e) => ({ success: 0, error: e })),
      timeout(500),
    ]).then(([result]: any) => {
      if (result.success) {
        res(result.result);
      } else {
        rej(result.error);
      }
    });
  });
};
