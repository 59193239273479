<template>
  <div :class="['hot-news__grid', adaptive ? 'hot-news__grid--adaptive' : '']">
    <router-link
      v-for="item of items"
      :key="item.link"
      :to="$routerHelper.withPlatformAndLang($route, item.link)"
      :class="hotNewsItemClassName(item.wide)"
      @click.native="$emit('item-click-native', item.title)"
    >
      <CompactArticleCard
        v-if="compactOnMobile && $bps('xs')"
        :date="item.release"
        :title="item.title"
      ></CompactArticleCard>
      <ArticleCard
        v-else
        hover-effect
        :img="item.imageUrl"
        :text="item.description"
        :date="item.release"
        :title="item.title"
        :adaptive="adaptive"
      ></ArticleCard>
    </router-link>
  </div>
</template>
<script>
import { getModule } from 'vuex-module-decorators';
import ArticleCard from '@themes/v1/components/ArticleCard';
import CompactArticleCard from '@themes/v1/components/CompactArticleCard';
import { AppStore } from '@/themes/v1/stores/app.store';

export default {
  name: 'HotNews',
  components: { ArticleCard, CompactArticleCard },
  props: ['news', 'adaptive', 'compactOnMobile'],
  methods: {
    hotNewsItemClassName(wide) {
      const classNames = ['hot-news__item'];
      if (this.$bps('xs') && this.compactOnMobile) {
        classNames.push('hot-news__item--compact');
      }
      if (wide) {
        classNames.push('hot-news__item--wide');
      }
      return classNames;
    },
  },
  computed: {
    items() {
      if (this.news == null || this.news.length === 0) {
        return [];
      }
      const appStore = getModule(AppStore, this.$store);
      const section = appStore.sections['useful-articles'];
      const descriptionPlaceholder =
        section &&
        section.articlePageMetadata &&
        section.articlePageMetadata.description
          ? section.articlePageMetadata.description
          : '{description}';
      return this.news.map((i) => ({
        link: i.item.link.replace(/^\//, ''),
        title: i.item.title,
        description: descriptionPlaceholder.replace(
          '{description}',
          i.item.description
        ),
        release: i.item.release,
        imageUrl: i.wide ? i.item.thumbnails.large : i.item.thumbnails.medium,
        wide: i.wide,
      }));
    },
  },
};
</script>

<style scoped></style>
