<template>
  <div class="styled-select" :class="{ opened: isOpen }" v-click-outside="hide">
    <div class="styled-select__control" @click="disabled ? null : toggle()">
      <slot v-if="$slots.selected" name="selected"></slot>
      <template v-else-if="selected">
        <span
          v-if="selected.icon"
          class="icon"
          :class="'icon-' + selected.icon"
        ></span>

        {{ selected.title }}
      </template>
    </div>
    <ul v-if="isOpen" class="styled-select__options">
      <template v-for="option in options">
        <li
          v-if="!selected || option.value !== selected.value"
          :key="option.value"
          @click="change(option)"
        >
          <span
            v-if="option.icon"
            class="icon"
            :class="'icon-' + option.icon"
          ></span>
          {{ option.title }}
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';

export default {
  directives: {
    ClickOutside,
  },
  data() {
    return {
      selected: null,
      isOpen: false,
    };
  },
  props: ['value', 'options', 'disabled'],
  watch: {
    value() {
      this.setSelected();
    },
    options() {
      this.setSelected();
    },
  },
  mounted() {
    this.setSelected();
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
    change(option) {
      this.isOpen = false;
      this.selected = option;
      this.$emit('change', option.value);
    },
    setSelected() {
      if (this.value && this.options) {
        this.selected = this.options.find((o) => o.value === this.value);
      } else {
        this.selected = null;
      }
    },
    hide() {
      if (this.isOpen) {
        this.isOpen = false;
      }
    },
  },
};
</script>
