











import { Component, Vue, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { AppStore } from '../stores/app.store';

@Component
export default class InGameFooter extends Vue {
  @Prop({ type: Boolean })
  smallSpace!: boolean;

  appModule = getModule(AppStore, this.$store);

  get textLinks() {
    if (this.appModule?.footerLinks?.additionalLinks == null) {
      return [];
    }
    const platform = this.$route.params.platform;
    const language = this.$route.params.lang;
    return this.appModule.footerLinks.additionalLinks
      .filter((x) => x.inGameVisible)
      .map((link) => ({
        uri: link?.uri
          .replace(/\{platform\}/g, platform)
          .replace(/\{language\}/g, language),
        text: link.text,
      }));
  }

  goTo(uri: string) {
    const portalUrl = location.origin;
    if (uri.includes(portalUrl)) {
      this.$router.push(uri.replace(portalUrl, ''));
    } else {
      location.replace(uri);
    }
  }
}
