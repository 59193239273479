
















import { Watch, Component, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { AppStore } from '@/themes/v1/stores/app.store';
import Sidemenu from '@/themes/v1/layouts/Sidemenu.vue';
import {
  scrollBlock,
  scrollUnblock,
  isScrollBlocked,
} from '../fns/scroll-blocker';
@Component({
  components: { Sidemenu },
})
export default class DrawerMenu extends Vue {
  appModule = getModule(AppStore, this.$store);

  @Watch('appModule.showMenu')
  onToggle(opened) {
    if (opened && !isScrollBlocked()) {
      scrollBlock();
    } else if (!opened && isScrollBlocked) {
      scrollUnblock();
    }
  }
}
