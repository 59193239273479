<template>
  <IngameLayout v-if="$route.params.platform === 'ingame'">
    <router-view></router-view>
  </IngameLayout>
  <WebLayout v-else>
    <router-view></router-view>
  </WebLayout>
</template>

<script>
import IngameLayout from './InGameLayout.vue';
import WebLayout from './WebLayout.vue';
export default {
  name: 'Layout',
  components: { WebLayout, IngameLayout },
};
</script>

<style scoped></style>
