










import { Component, Vue } from 'vue-property-decorator';
import { OnMounted, OnDestroyed } from '@/core/vue.types';

@Component
export default class QuestionMark
  extends Vue
  implements OnMounted, OnDestroyed {
  // eslint-disable-next-line no-undef
  askQuestionButtonsRefs!: NodeListOf<Element> | null;
  visible = false;

  mounted() {
    this.askQuestionButtonsRefs = document.querySelectorAll(
      '[data-id="ask-question-button"]'
    );
    window.addEventListener('scroll', this.onWindowScroll);
    this.onWindowScroll();
  }

  destroyed() {
    window.removeEventListener('scroll', this.onWindowScroll);
  }

  onWindowScroll() {
    this.askQuestionButtonsRefs = document.querySelectorAll(
      '[data-id="ask-question-button"]'
    );
    if (this.askQuestionButtonsRefs && this.askQuestionButtonsRefs.length > 0) {
      let atLeastOneButtonInViewport = false;
      this.askQuestionButtonsRefs.forEach((button) => {
        if (this.$utils.isInViewport(button)) {
          atLeastOneButtonInViewport = true;
        }
      });
      atLeastOneButtonInViewport
        ? (this.visible = false)
        : (this.visible = true);
    } else {
      this.visible = true;
    }
  }

  onAskQuestionClick() {
    this.$track(
      'ask_question',
      'click_button_ask_question_fly',
      this.$route.fullPath
    );
  }
}
