import Vue from 'vue';
import VueRouter from 'vue-router';
import VueI18n from 'vue-i18n';
import Vuex, { Store } from 'vuex';
import PortalVue from 'portal-vue';
import Vue2TouchEvents from 'vue2-touch-events';
import VueGtag from 'vue-gtag';
import VueYandexMetrika from 'vue-yandex-metrika';
import VueTextareaAutosize from 'vue-textarea-autosize';

import { createStore as createStoreWithModules } from '@/store';

import App from './app.vue';
import { routes } from './routes';
import { dateTimeFormats, numberFormats, messages } from './i18n';
import { RouterHelperPlugin } from '@/themes/v1/plugins/router-helper';
import { PlayerStore } from '@/themes/v1/stores/player.store';
import { AppStore } from '@/themes/v1/stores/app.store';
import { UtilsPlugin } from '@/themes/v1/plugins/utils';
import { DateHelperPlugin } from '@/themes/v1/plugins/date-helper';
import { NumberHelperPlugin } from '@/themes/v1/plugins/number-helper';
import { createHttpClient } from '@/core/http/http-client';
import { EnvironmentService } from '@/modules/guide/environment/environment.service';
import { SearchService } from '@/modules/guide/search/search.service';
import { previewModeInterceptor } from './core/interceptors/preview-mode.interceptor';
import {
  playerAuthReqInterceptor,
  playerAuthResInterceptor,
} from './core/interceptors/player-auth.interceptor';
import { errorResInterceptor } from './core/interceptors/error.interceptor';
import { ChatService } from '@/modules/sts/chat/chat.service';
import { IInterceptors } from '@/core/http/http-client.types';
import { TemporaryProblemsService } from '@/modules/sts/temporary-problems';
import { ProblemsStore } from '@/themes/v1/stores/problems.store';
import { ArticleService } from '@/modules/guide/articles/article.service';
import { GuideStore } from '@/themes/v1/stores/guide.store';
import { TicketCreatorService } from '@/modules/sts/creator/tickets-creator.service';
import { FeedbackService } from '@/modules/sts/feedback/feedback.service';
import { UnsubscribingService } from '@/modules/sts/unsubscribing/unsubscribing.service';
import { ChatStore } from './stores/chat.store';
import { TicketsStore } from './stores/tickets.store';
import scrollBehaviorRules from './core/routes/scroll-behavior-rules';
import { LayoutPlugin } from '@/themes/v1/plugins/layout';
import { UiStore } from '@/themes/v1/stores/ui.store';
import { BreakpointsPlugin } from './plugins/breakpoints';
import { AnalyticsPlugin } from './plugins/analytics';
import env from '@/env';
import { NotificationsStore } from './stores/notifications.store';
import { DEFAULT_LANGUAGE } from './core/config/defaults';

function makeHttp(store: Store<any>) {
  const http = createHttpClient();
  const interceptors: IInterceptors = {
    request: [previewModeInterceptor, playerAuthReqInterceptor],
    response: [errorResInterceptor, playerAuthResInterceptor],
  };
  interceptors.request.forEach((makeInterceptor) => {
    const { onFulfilled, onRejected } = makeInterceptor(store);
    http.interceptors.request.use(onFulfilled, onRejected);
  });
  interceptors.response.forEach((makeInterceptor) => {
    const { onFulfilled, onRejected } = makeInterceptor(store);
    http.interceptors.response.use(onFulfilled, onRejected);
  });
  return http;
}

function createI18n() {
  return new VueI18n({
    locale: DEFAULT_LANGUAGE,
    messages,
    dateTimeFormats: dateTimeFormats,
    numberFormats,
    fallbackLocale: DEFAULT_LANGUAGE,
  });
}

function createRouter() {
  return new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
      if (to.hash) {
        return {
          selector: to.hash,
          offset: { x: 0, y: 100 },
          behavior: 'smooth',
        };
      } else if (savedPosition != null) {
        return savedPosition;
      } else {
        const result = scrollBehaviorRules(from, to);
        if (result !== false) {
          return result;
        }
      }
      return { x: 0, y: 0 };
    },
  });
}

function createStore() {
  return createStoreWithModules({
    app: AppStore,
    guide: GuideStore,
    player: PlayerStore,
    problems: ProblemsStore,
    chat: ChatStore,
    tickets: TicketsStore,
    ui: UiStore,
    notifications: NotificationsStore,
  });
}

export const createApp = () => {
  const router = createRouter();

  if (env.isClient) {
    Vue.use(
      VueGtag,
      {
        config: {
          id: env.gtagId,
        },
        disableScriptLoad: env.gtagId == null || process.server,
        enabled: env.gtagId != null && process.client,
        pageTrackerEnabled: false,
      },
      router
    );

    Vue.use(VueYandexMetrika, {
      id: env.yandexMetricaId,
      router: router,
      env: process.env.NODE_ENV,
      options: {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
      },
    });

    //unregister serviceWorker in client
    console.log('try to unregister service-workers');
    try {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations().then(
          function (registrations) {
            if (registrations) {
              for (const registration of registrations) {
                registration.unregister();
              }
            }
          },
          (reason) => console.log(reason)
        );
      }
    } catch (err) {
      console.log('An error occured during SW getting registration', err);
    }
  }

  const store = createStore();
  const httpClient = makeHttp(store);

  return {
    router: router,
    store: store,
    i18n: createI18n(),
    render: (h) => h(App),
    provide: function () {
      return {
        httpClient,
        environmentService: new EnvironmentService(httpClient),
        searchService: new SearchService(httpClient),
        chatService: new ChatService(httpClient),
        temporaryProblemsService: new TemporaryProblemsService(httpClient),
        articleService: new ArticleService(httpClient),
        ticketCreatorService: new TicketCreatorService(httpClient),
        feedbackService: new FeedbackService(httpClient),
        unsubscribingService: new UnsubscribingService(httpClient),
      };
    },
  };
};

export const setupPlugins = (): void => {
  Vue.use(VueRouter);
  Vue.use(Vuex);
  Vue.use(VueI18n);
  Vue.use(PortalVue);
  Vue.use(RouterHelperPlugin);
  Vue.use(UtilsPlugin);
  Vue.use(LayoutPlugin);
  Vue.use(DateHelperPlugin);
  Vue.use(NumberHelperPlugin);
  Vue.use(Vue2TouchEvents);
  Vue.use(BreakpointsPlugin);
  Vue.use(AnalyticsPlugin);
  Vue.use(VueTextareaAutosize);

  if (process.client) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const Sticky = require('vue-sticky-directive');
    Vue.directive('Sticky', Sticky.default);
  }
};
