import { AxiosInstance } from 'axios';

import { Guid, LanguageCode } from '@/core/system';
import { FeedbackOptions } from './feedback-options.model';
import { FeedbackStatus } from './feedback-status.model';
import { SendFeedbackCommand } from './send-feedback.model';
import config from '@/env';

export class FeedbackService {
  constructor(private readonly $http: AxiosInstance) {}

  async getOptions(language: LanguageCode): Promise<FeedbackOptions> {
    const { data } = await this.$http.get<FeedbackOptions>(
      `/tickets/feedback/options`,
      {
        params: { game: config.ticketsGame, language },
      }
    );
    return data;
  }

  async getStatus(ticketId: Guid): Promise<FeedbackStatus> {
    const { data } = await this.$http.get<FeedbackStatus>(
      `/tickets/feedback/${ticketId}`,
      {
        params: { game: config.ticketsGame },
      }
    );
    return data;
  }

  async sendFeedback(command: SendFeedbackCommand): Promise<boolean> {
    const response = await this.$http.post(`/tickets/feedback`, command);
    return response.status === 200;
  }
}
