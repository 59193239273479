import { AxiosInstance } from 'axios';
import { Guid } from '@/core/system';
import { Review } from './review.model';

export class ReviewsService {
  constructor(private readonly $http: AxiosInstance) {}

  async send(review: Review): Promise<Guid> {
    const { data } = await this.$http.post<Guid>(`/article/review`, review);
    return data;
  }
}
