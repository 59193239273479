











import { Component, Vue, Watch } from 'vue-property-decorator';
import { OnCreated, OnMounted, WithMeta } from '@/core/vue.types';
import { MetaInfo } from 'vue-meta';
import { DevtoolsAsync } from './devtools';
import env from '@/env';
import { ErrorWithStatus, SystemStore } from '@/core/system';
import { getModule } from 'vuex-module-decorators';
import UiSizeMonitor from '@/themes/v1/core/ui/UiSizeMonitor.vue';
import Notifications from '@/themes/v1/components/Notifications/Notifications.vue';
import { timeout } from '@/core/utils';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const NoSsr = require('vue-no-ssr');

@Component({
  name: 'App',
  components: {
    UiSizeMonitor,
    DevtoolsAsync,
    NoSsr,
    Notifications,
  },
})
export default class AppInit
  extends Vue
  implements WithMeta, OnCreated, OnMounted {
  metaInfo(): MetaInfo {
    return {
      title: 'Support Portal',
      meta: [{ name: 'theme-color', content: '#100101' }],
      afterNavigation: (info) => {
        timeout(1000).then(() => {
          this.$trackPage(info.title);
        });
      },
    };
  }

  get devtools() {
    return env.devtools;
  }

  created(): void {
    const systemStore = getModule(SystemStore, this.$store);
    if (systemStore.error) {
      this.handleError(systemStore.error);
      systemStore.resetError();
    }
  }

  handleError(error: ErrorWithStatus) {
    this.$error(error, error.status);
  }

  mounted() {
    this.$trackPage();
  }

  @Watch('$route')
  routeChanged() {
    this.$analyticsUpdatePage();
  }
}
