import { PluginObject } from 'vue';

const escapedGroups = new Map<string, string>([
  ['<empty>', ''],
  ['<space>', ' '],
]);

function handleEscapedSymbols(dict: Map<string, string>, input: string) {
  if (dict.has(input)) {
    return dict.get(input);
  }
  return input;
}

function handlePart($t: (path: string) => string, part: Intl.NumberFormatPart) {
  switch (part.type) {
    case 'group':
      return handleEscapedSymbols(
        escapedGroups,
        $t('common.numbers.group-separator')
      );
    default:
      return part.value;
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export type LocalizeNumberFn = (str: number) => string;

export const NumberHelperPlugin: PluginObject<void> = {
  install(Vue) {
    Vue.prototype.$nf = function (number) {
      try {
        const parts = new Intl.NumberFormat(
          this.$t('common.numbers.code')
        ).formatToParts(number);
        const $tBinded = this.$t.bind(this);
        return parts.reduce((accum, part) => {
          return accum + handlePart($tBinded, part);
        }, '');
      } catch {
        if (number.toString) {
          return number.toString();
        }
        return number;
      }
    };
  },
};
