import { PluginObject } from 'vue';
import dayjs from 'dayjs';

import 'dayjs/locale/en';
import 'dayjs/locale/de';
import 'dayjs/locale/fr';
import 'dayjs/locale/ru';
import 'dayjs/locale/es';
import 'dayjs/locale/it';
import 'dayjs/locale/ko';
import 'dayjs/locale/tr';
import 'dayjs/locale/ja';
import updateLocale from 'dayjs/plugin/updateLocale';

dayjs.extend(updateLocale);

dayjs.updateLocale('es', {
  monthsShort: [
    'ENE',
    'FEB',
    'MAR',
    'ABR',
    'MAY',
    'JUN',
    'JUL',
    'AGO',
    'SEP',
    'OCT',
    'NOV',
    'DIC',
  ],
});
dayjs.updateLocale('it', {
  monthsShort: [
    'GEN',
    'FEB',
    'MAR',
    'APR',
    'MAG',
    'GIU',
    'LUG',
    'AGO',
    'SET',
    'OTT',
    'NOV',
    'DIC',
  ],
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export type LocalizeDateFn = (str: string) => string;

export const DateHelperPlugin: PluginObject<void> = {
  install(Vue) {
    Vue.prototype.$localizeDateTime = function (date) {
      return dayjs(date)
        .locale(this.$i18n.locale)
        .format(this.$t('common.datetime format'));
    };
    Vue.prototype.$localizeDate = function (date) {
      return dayjs(date)
        .locale(this.$i18n.locale)
        .format(this.$t('common.date format'));
    };

    Vue.prototype.$localizeTime = function (date) {
      return dayjs(date)
        .locale(this.$i18n.locale)
        .format(this.$t('common.time format'));
    };
  },
};
