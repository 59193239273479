

















import { Component, Vue } from 'vue-property-decorator';
import Search from '@/themes/v1/containers/Search.vue';
import LayoutHeaderExtends from '@/themes/v1/layouts/LayoutHeaderExtends.vue';
import LayoutShadow from '@/themes/v1/layouts/LayoutShadow.vue';
import { getModule } from 'vuex-module-decorators';
import { AppStore } from '../../stores/app.store';

@Component({
  components: {
    LayoutShadow,
    LayoutHeaderExtends,
    Search,
  },
})
export default class GuideSearchWrapper extends Vue {
  showShadow = false;
  appModule = getModule(AppStore, this.$store);

  $refs!: {
    search: any;
  };

  clear() {
    this.$refs.search.cancel();
    this.showShadow = false;
  }
}
