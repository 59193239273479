import { AxiosInstance } from 'axios';

import { GameId, LanguageCode } from '@/core/system';
import { SearchableArticle } from './searchable-article.model';
import { SearchResult } from './search-result.model';

export class SearchService {
  constructor(private readonly $http: AxiosInstance) {}

  async getSearchableArticles(
    game: GameId,
    language: LanguageCode
  ): Promise<SearchableArticle[]> {
    const { data } = await this.$http.get<SearchableArticle[]>(
      `/search/searchable-articles`,
      {
        params: { game, language },
      }
    );
    return data;
  }

  async search(
    game: GameId,
    language: LanguageCode,
    query: string,
    skip: number,
    itemsPerPage: number
  ): Promise<SearchResult> {
    const { data } = await this.$http.get<SearchResult>(`/search`, {
      params: { game, language, query, skip, itemsPerPage },
    });
    return data;
  }
}
