import { PluginObject } from 'vue';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UtilsPlugin {
  strToDate(str: string): Date;
  isInViewport(el): boolean;
}

export const UtilsPlugin: PluginObject<void> = {
  install(Vue) {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    Vue.prototype.$utils = {
      strToDate: (str) => new Date(str),
      isInViewport(el) {
        const rect = el.getBoundingClientRect();
        if (rect.width === 0 || rect.height === 0) return false;
        return rect.top < window.innerHeight && rect.bottom >= 0;
      },
    } as UtilsPlugin;
  },
};
