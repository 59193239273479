











































import { Watch, Component, Vue } from 'vue-property-decorator';
import HeaderPanel from '@/themes/v1/layouts/HeaderPanel.vue';
import DrawerMenu from '@/themes/v1/layouts/DrawerMenu.vue';
import Box from '@/themes/v1/components/Box/Box.vue';
import Footer from '@/themes/v1/layouts/Footer.vue';
import BoxContent from '@/themes/v1/components/Box/BoxContent.vue';
import SectionCircleLink from '@/themes/v1/components/SectionCircleLink.vue';
import FaqAndGuideLinks from '@/themes/v1/components/FaqAndGuideLinks.vue';
import { LanguageCode, SystemStore } from '@/core/system';
import { getModule } from 'vuex-module-decorators';
import { OnMounted } from '@/core/vue.types';
import InGameHeader from '@/themes/v1/layouts/InGameHeader.vue';
import InGameFooter from '@/themes/v1/layouts/InGameFooter.vue';
import { loggingService } from '@/modules/logging.service';
import { parseError, parseErrorStack } from '@/core/error-handling/func';

@Component({
  components: {
    InGameFooter,
    InGameHeader,
    FaqAndGuideLinks,
    SectionCircleLink,
    BoxContent,
    Box,
    DrawerMenu,
    HeaderPanel,
    Footer,
  },
})
export default class Error extends Vue implements OnMounted {
  systemStore = getModule(SystemStore, this.$store);
  // todo: mountedTime needed for situations when component mounted before route changing
  private mountedTime!: number;
  private language!: LanguageCode;

  get code() {
    return this.$errorHandler?.statusCode || 500;
  }

  mounted() {
    this.mountedTime = new Date().getTime();
    this.language = this.$i18n.locale;

    loggingService.log({
      src: process.server ? 'ssr' : 'spa',
      url: this.$context.url,
      error: parseError(this.$errorHandler?.error),
      stack: parseErrorStack(this.$errorHandler?.error),
      statusCode: this.$errorHandler?.statusCode,
    });
  }

  @Watch('$i18n.locale')
  onLocaleChanging(locale) {
    // if url doesnt have language parameter
    // we remember it
    if (this.$route.params.lang == null) {
      this.language = locale;
    }
  }
}
