


































import { Component, Vue, Prop } from 'vue-property-decorator';
import ForeignLinks from '@/themes/v1/containers/ForeignLinks.vue';
import LanguageSelector from '@/themes/v1/containers/LanguageSelector.vue';
import { getModule } from 'vuex-module-decorators';
import { AppStore } from '@/themes/v1/stores/app.store';
import { makePathWithLangAndPlatform } from '../fns/router';
import fallbackLanguages from '@/themes/v1/core/config/fallback-languages';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const NoSsr = require('vue-no-ssr');
@Component({
  components: { LanguageSelector, ForeignLinks, NoSsr },
})
export default class HeaderPanel extends Vue {
  @Prop({ type: Boolean, default: false })
  disabled!: boolean;

  appModule = getModule(AppStore, this.$store);

  get links() {
    return this.appModule.foreignLinks;
  }

  get languages() {
    return this.appModule.languages || fallbackLanguages(this.$i18n);
  }

  get showLinks() {
    return this.links && this.links.length > 1;
  }

  changeLanguage(lang) {
    this.$i18n.locale = lang;
    this.$router.push({ params: { lang }, query: { ...this.$route.query } });
  }

  onLogoClick() {
    if (this.disabled === true) {
      return;
    }
    const homePagePath = makePathWithLangAndPlatform(
      this.$route,
      '',
      this.$i18n.locale
    );
    if (this.$route.path === homePagePath) {
      return;
    }
    this.$router.push(homePagePath);
  }
}
