






import { Component, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { Devices, UiStore } from '@/themes/v1/stores/ui.store';
import { OnMounted } from '@/core/vue.types';
import toPairs from 'lodash/toPairs';
import last from 'lodash/last';
import debounce from 'lodash/debounce';

const devicesToVariableMap = {
  [Devices.xs]: '575',
  [Devices.sm]: '767',
  [Devices.md]: '991',
  [Devices.lg]: '1170',
  // [Devices.xl]: '', // Default
};

@Component
export default class UiSizeMonitor extends Vue implements OnMounted {
  uiStore = getModule(UiStore, this.$store);

  mounted() {
    this.setSize();
    window.addEventListener('resize', this.onResizeDebounce);
  }

  private onResize = () => {
    this.setSize();
  };

  private onResizeDebounce = debounce(this.onResize, 300);

  private setSize() {
    this.uiStore.setDevice(this.getSize(window));
  }

  private getSize(windowEl: Window) {
    const type = last(
      toPairs(devicesToVariableMap)
        .reverse()
        .map((x) => {
          return [x[0], windowEl.matchMedia(`(max-width: ${x[1]}px)`).matches];
        })
        .filter((x) => x[1])
    );
    return type ? (type[0] as Devices) : Devices.xl;
  }
}
