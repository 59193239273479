import Vuex, { ModuleTree } from 'vuex';
import { AppState } from './app-state';
import { SystemStore } from '@/core/system';

export const createStore = (modules: ModuleTree<any> = {}) => {
  return new Vuex.Store<AppState>({
    modules: {
      ...modules,
      system: SystemStore,
    },
  });
};
