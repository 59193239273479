import { AxiosInstance } from 'axios';
import { httpClient } from '@/core/http';

export class LoggingService {
  constructor(private readonly http: AxiosInstance) {}

  log(payload: { [key: string]: any }) {
    const formData = new FormData();
    if (formData != null) {
      Object.entries(payload).forEach(([key, value]) => {
        if (value == null) {
          return;
        }
        if (typeof value === 'string') {
          formData.append(key, value);
        } else {
          formData.append(key, JSON.stringify(value));
        }
      });
    }
    return this.http.post('/logging/error', formData).catch(() => null);
  }
}

export const loggingService = new LoggingService(httpClient);
