import env from '@/env';
import { PluginObject } from 'vue';

export const AnalyticsPlugin: PluginObject<void> = {
  install(Vue) {
    Vue.prototype.$track = function (
      category: string,
      event: string,
      label?: string
    ) {
      this.$gtag.event(event, { event_category: category, event_label: label });
    };

    Vue.prototype.$trackPage = function (title?: string) {
      if (!trackAllowed(this.$route)) {
        return;
      }
      const trackOptions = getBaseParams(this.$route, title);
      this.$gtag.pageview(trackOptions);
    };

    Vue.prototype.$analyticsUpdatePage = function () {
      if (!trackAllowed(this.$route)) {
        return;
      }
      const trackOptions = getBaseParams(this.$route);
      this.$gtag.config({
        ...trackOptions,
        send_page_view: false,
      });
    };

    const trackAllowed = (route: any): boolean => {
      if (!env.isClient || route.meta.doNotTrackPage) {
        return false;
      }
      return true;
    };

    const getBaseParams = (route: any, title?: string) => {
      return {
        page_title: title ?? document.title,
        page_path: route.fullPath,
        page_location: window.location.href,
      };
    };
  },
};
