




import { Component, Inject, Provide, Vue } from 'vue-property-decorator';
import { AxiosInstance } from 'axios';
import { PopularService } from '@/modules/guide/popular';
import { ReviewsService } from '@/modules/guide/reviews/reviews.service';
import { WithAsyncData } from '@/core/vue.types';
import { getModule } from 'vuex-module-decorators';
import { AppStore } from '../../stores/app.store';

@Component({
  components: {},
})
export default class GuideRoot extends Vue implements WithAsyncData {
  @Inject()
  readonly httpClient!: AxiosInstance;

  @Provide()
  popularService = new PopularService(this.httpClient);

  @Provide()
  reviewsService = new ReviewsService(this.httpClient);

  async asyncData({ store, route }): Promise<void> {
    const previewVersion = route.query.preview;
    if (previewVersion) {
      const appStore = getModule(AppStore, store);
      appStore.setPreviewMode(previewVersion);
    }
  }
}
