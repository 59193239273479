import { IResInterceptor } from '@/core/http/http-client.types';
import { ErrorWithStatus, SystemStore } from '@/core/system';
import { getModule } from 'vuex-module-decorators';

export const errorResInterceptor: IResInterceptor = (store) => ({
  onFulfilled(res) {
    return res;
  },
  onRejected(err) {
    const systemStore = getModule(SystemStore, store);

    const details = {
      type: 'api',
      response: { ...err.response },
      request: { ...err.request },
    };
    if (err.response?.status === 404 || err.response?.status === 500) {
      systemStore.setError(
        new ErrorWithStatus(
          'HttpRequest was failed',
          err.response.status,
          details
        )
      );
    }
    return Promise.reject(err);
  },
});

class HttpError extends Error {
  constructor(
    public readonly statusCode: number,
    public readonly details: any
  ) {
    super('Request was failed');
  }
}
