import { mimes } from 'vee-validate/dist/rules.umd';
import env from '@/env';
import last from 'lodash/last';

export const banEmails = {
  validate(val: string) {
    return ![
      'support.team@plarium.com',
      'games.support@plarium.com',
      'top.vikings@plarium.com',
      'vikingsweb.support@plarium.com',
      'moderatorteam@plarium.com',
      'gdpr.cases@plarium.com',
      'magicwars@plarium.com',
      'magicwars.support@plarium.com',
      'throne.support@plarium.com',
      'throneweb.support@plarium.com',
      'terminator.support@plarium.com',
      '@okta.com',
      'top.lords@plarium.com',
      'top.commanders@plarium.com',
      'top.rulers@plarium.com',
      'survey@plarium.com',
      'support@lostislandhelp.zendesk.com',
      'support@plarium-play-faq.zendesk.com',
      'support@plariumsupport.zendesk.com',
      'forum@plariumsupport.zendesk.com',
      'gamehq@plariumsupport.zendesk.com',
      'gamesapp@plariumsupport.zendesk.com',
      'kayako@plarium.com',
      'lostisland@plariumsupport.zendesk.com',
      'privacy@plarium.com',
      'web_gl@plariumsupport.zendesk.com',
      'support@plarium-mobile-support.zendesk.com',
      'arena.support@plarium.com',
      'raid.support@plarium.com',
      'survival.support@plarium.com',
      'support@wordvineshelp.zendesk.com',
    ].some((email) => val.toLowerCase().trim().includes(email.toLowerCase()));
  },
};

export const validateFile: (file: File) => any = (file: File) => {
  if (file.type) {
    return mimes.validate(file, env.supportAttachments);
  } else {
    // Because if windows/macos dont has application for this file the file.type will be empty
    const ext = last(file.name.split('.')) || '';
    return env.supportAttachmentsExt.includes(ext.toLowerCase());
  }
};
