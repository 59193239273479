import { HotNewsPreview, NewsPreview } from '@/modules/guide/articles';

export default function (
  items: NewsPreview[],
  rows: number,
  itemsPerRow: number
): HotNewsPreview[] {
  if (items.length === itemsPerRow) {
    return items.map((item) => ({ item, wide: false }));
  }
  const result: HotNewsPreview[] = [];
  for (let row = 0; row < rows; row++) {
    let needWide = row % 2 === 1;
    let vacancies = itemsPerRow;
    while (vacancies > 0) {
      const item = items.shift();
      if (item == null) {
        return result;
      }
      const resultItem = {
        item,
        wide: needWide && vacancies > 1 && item.thumbnails.large != null,
      };
      result.push(resultItem);
      vacancies -= resultItem.wide ? 2 : 1;
      needWide = !needWide;
    }
  }
  return result;
}
