import { Route } from 'vue-router';
import { PositionResult } from 'vue-router/types/router';

interface RouterScrollBehaviorRule {
  from: RegExp;
  to: RegExp;
  func: (from: Route, to: Route) => PositionResult | null;
}

const rules: RouterScrollBehaviorRule[] = [
  {
    from: /^\/([A-Za-z0-9-]+)\/([A-Za-z0-9-]+)\/faq/i,
    to: /^\/([A-Za-z0-9-]+)\/([A-Za-z0-9-]+)\/faq\/.*/i,
    func: () => null,
  },
  {
    from: /^\/([A-Za-z0-9-]+)\/([A-Za-z0-9-]+)\/faq\/.*/i,
    to: /^\/([A-Za-z0-9-]+)\/([A-Za-z0-9-]+)\/faq/i,
    func: () => null,
  },
];

export default function (
  from: Route,
  to: Route
): PositionResult | null | false {
  const rule = rules.find((r) => r.from.test(from.path) && r.to.test(to.path));
  if (rule == null) {
    return false;
  } else {
    return rule.func(from, to);
  }
}
