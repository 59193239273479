










import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class ArticleCard extends Vue {
  @Prop({ type: String, required: true })
  title!: string;

  @Prop({ type: String, required: false })
  date!: string;
}
