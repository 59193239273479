import { Module, VuexModule, Mutation } from 'vuex-module-decorators';

export enum Devices {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
}

@Module({ name: 'ui', stateFactory: true, namespaced: true })
export class UiStore extends VuexModule {
  device: Devices = Devices.xl;

  @Mutation
  setDevice(device: Devices) {
    this.device = device;
  }
}
