import axios from 'axios';
import { stringify } from 'qs';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const https = require('https');
import config from '../../env';

export function createHttpClient() {
  return config.isClient
    ? axios.create({
        baseURL: config.apiUrl,
        paramsSerializer: (params) =>
          stringify(params, { indices: false, skipNulls: true }),
      })
    : axios.create({
        httpsAgent: new https.Agent({ rejectUnauthorized: false }),
        baseURL: config.apiUrl,
      });
}

export const httpClient = createHttpClient();
