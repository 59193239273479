





















































import { Component, Vue, Emit, Prop } from 'vue-property-decorator';
import TextHighlight from './TextHighlight.vue';
import QuestionMark from '@/themes/v1/components/QuestionMark.vue';
import Spinner from './Spinner.vue';

@Component({
  components: {
    TextHighlight,
    Spinner,
    QuestionMark,
  },
  model: {
    prop: 'value',
    event: 'input',
  },
})
export default class Search extends Vue {
  @Prop({ type: String })
  value!: string;

  @Prop({ type: Boolean })
  loading!: boolean;

  @Prop({ type: Boolean })
  empty!: boolean;

  @Prop({ type: Array, default: () => [] })
  items!: any[];

  @Emit('click')
  onClick(item: any) {
    this.$track('search', 'click_search_item', item.name);
    return item;
  }

  @Emit('input')
  onInput(value: string) {
    return value;
  }

  @Emit('submit')
  onSubmit(value: string) {
    return value;
  }

  onButtonClick() {
    this.$track('search', 'click_search_button', this.value);
    this.onSubmit(this.value);
  }

  onFormSubmit(value: string) {
    this.$track('search', 'click_search_enter', value);
    this.onSubmit(value);
  }

  deleteText() {
    this.onInput('');
  }
}
