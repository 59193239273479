<template>
  <router-link
    :to="path || segment"
    append
    class="section-link"
    :class="[`section-link--${segment}`, loading && `section-link--loading`]"
    @click.native="onClick()"
  >
    <CircleWithTitle><slot></slot></CircleWithTitle>
  </router-link>
</template>

<script>
import CircleWithTitle from '@themes/v1/components/CircleWithTitle';

export default {
  name: 'SectionCircleLink',
  components: { CircleWithTitle },
  props: {
    segment: String,
    path: String,
    loading: Boolean,
  },
  methods: {
    onClick() {
      if (this.segment === 'ask') {
        this.$track(
          'ask_question',
          'click_button_ask_question',
          this.$route.fullPath
        );
      }
    },
  },
};
</script>

<style scoped></style>
