import config from '@/env';

const scrollblockClassName = 'popup-scrollblock';
let lastScrollTop: number | null = null;

export const scrollBlock = () => {
  if (!config.isClient) {
    return;
  }
  const rootEl = document.documentElement;
  lastScrollTop = rootEl.scrollTop;
  rootEl.style.top = `-${document.documentElement.scrollTop}px`;
  rootEl.classList.add(scrollblockClassName);
};

export const scrollUnblock = () => {
  if (!config.isClient) {
    return;
  }
  const rootEl = document.documentElement;
  rootEl.style.top = '';

  rootEl.classList.remove(scrollblockClassName);
  if (lastScrollTop != null) {
    window.scroll(0, lastScrollTop);
    lastScrollTop = null;
  }
};

export const isScrollBlocked = () => {
  if (!config.isClient) {
    return false;
  }
  const rootEl = document.documentElement;
  return rootEl.classList.contains(scrollblockClassName);
};
