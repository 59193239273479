
































import { Component, Vue, Prop } from 'vue-property-decorator';
import BgImage from './BgImage.vue';

@Component({ components: { BgImage } })
export default class ArticleCard extends Vue {
  @Prop({ type: String, required: true })
  img!: string;

  @Prop({ type: String })
  fallbackImageClass!: string;

  @Prop({ type: String, required: true })
  title!: string;

  @Prop({ type: String, required: false })
  text!: string;

  @Prop({ type: String, required: false })
  date!: string;

  @Prop({ type: Boolean, default: false })
  horizontal!: boolean;

  @Prop({ type: Boolean, default: false })
  hoverEffect!: boolean;

  @Prop({ type: Boolean, default: false })
  adaptive!: boolean;
}
