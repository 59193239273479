<template>
  <div
    class="box__content"
    :class="[
      `box--space-${space}`,
      withoutSplitter && 'box__content--without-splitter',
    ]"
    @click="$emit('click')"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'BoxContent',
  props: {
    space: {
      type: String,
      default: 'normal',
      validator(val) {
        return ['normal', 'medium', 'small', 'none'].includes(val);
      },
    },
    withoutSplitter: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped></style>
