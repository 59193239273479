












import { Component, Vue, Emit, Prop, Inject } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import Search from '@/themes/v1/components/Search.vue';
import { SearchService } from '@/modules/guide/search/search.service';
import config from '@/env';
import debounce from 'lodash/debounce';
import { GuideStore } from '../stores/guide.store';
import { SearchableArticle } from '@/modules/guide/search/searchable-article.model';

@Component({
  components: { Search },
})
export default class SearchContainer extends Vue {
  @Inject() searchService!: SearchService;

  @Prop({ type: Boolean })
  disabled!: boolean;

  search = '';
  loading = false;
  empty = false;

  lastSearch = '';
  items: { path: string; name: string; space: string; icon: string }[] = [];
  guideStore = getModule(GuideStore, this.$store);

  get matched() {
    const filter = new RegExp(this.search.trim(), 'i');
    return this.items.filter((x) => filter.test(x.name)).slice(0, 10);
  }

  async initData() {
    await this.find();
  }

  onSubmit() {
    if (!this.search) return;
    this.$router.push({
      path: `/${this.$route.params.platform}/${this.$route.params.lang}/search`,
      query: { query: this.search },
    });
    this.clear();
  }

  setValue(val: string) {
    this.search = val;
    if (this.search) {
      this.onLock(true);
      this.debounceFind();
    } else {
      this.clear();
    }
  }

  debounceFind = debounce(() => this.find(), 400);

  clear(silence = false) {
    this.items = [];
    this.loading = false;
    this.lastSearch = '';
    this.empty = false;
    if (!silence) {
      this.onLock(false);
    }
  }

  cancel() {}

  @Emit('lock')
  onLock(value: boolean) {
    return value;
  }

  goToArticle(item) {
    this.clear();
    this.$router.push(item.path);
  }

  get showMatched() {
    if (this.search === this.lastSearch) {
      return true;
    }
    return !this.loading;
  }

  private async find() {
    if (this.lastSearch === this.search && this.items.length) {
      return;
    }

    if (!this.search) return;

    this.empty = false;
    const entries = await this.getData();
    this.items = entries.map((i) => ({
      path: this.$routerHelper.withPlatformAndLang(this.$route, i.path),
      name: i.name,
      space: i.parent,
      icon: i.sectionSegment,
    }));
    this.lastSearch = this.search;
    this.empty = this.matched.length === 0;
  }

  private async getData(): Promise<SearchableArticle[]> {
    const storeData = this.guideStore.searchableArticles;
    if (
      storeData != null &&
      this.$i18n.locale === this.guideStore.resourceLanguage
    ) {
      return storeData;
    }

    this.loading = true;
    const items = await this.searchService.getSearchableArticles(
      config.game,
      this.$i18n.locale
    );
    if (this.$i18n.locale === this.guideStore.resourceLanguage) {
      this.guideStore.resetResourceLanguage(this.$i18n.locale);
    }
    this.guideStore.setSearchableArticles(items);
    this.loading = false;
    return items;
  }
}
