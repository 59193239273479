























import DrawerMenu from '@/themes/v1/layouts/DrawerMenu.vue';
import InGameHeader from './InGameHeader.vue';
import InGameFooter from './InGameFooter.vue';
import { Component, Vue } from 'vue-property-decorator';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const NoSsr = require('vue-no-ssr');

@Component({
  name: 'IngameLayout',
  components: {
    NoSsr,
    InGameHeader,
    DrawerMenu,
    InGameFooter,
  },
})
export default class IngameLayout extends Vue {
  get language() {
    return this.$store.state.language;
  }
  get languages() {
    return this.$store.state.languages;
  }
  get footerLinks() {
    return this.$store.state.footerLinks;
  }
  get showHeader() {
    return this.$route?.meta?.inGameOptions?.hideHeader !== true;
  }
  get showFooter() {
    return this.$route?.meta?.inGameOptions?.hideFooter !== true;
  }
  get fullscreen() {
    return this.$route?.meta?.inGameOptions?.fullscreen === true;
  }

  get mainCenter() {
    return this.$route?.meta?.ingameLayout?.mainCenter === true;
  }

  changeLanguage(lang) {
    this.$store.commit('setLanguage', lang);
    this.$router.push({ params: { lang } });
  }
}
