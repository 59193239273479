import en from './en.json';
import de from './de.json';
import es from './es.json';
import fr from './fr.json';
import it from './it.json';
import ja from './ja.json';
import ko from './ko.json';
import ru from './ru.json';
import tr from './tr.json';

import dateTimeFormats from './date-time-formats';
import { numberFormats } from './number-format';

export const messages = {
  en,
  de,
  es,
  fr,
  it,
  ja,
  ko,
  ru,
  tr,
};

export { dateTimeFormats, numberFormats };
