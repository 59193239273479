





import { Component, Vue } from 'vue-property-decorator';
import { OnCreated, SsrCtx, WithAsyncData } from '@/core/vue.types';
import { getModule } from 'vuex-module-decorators';
import { AppStore } from '@/themes/v1/stores/app.store';
import { EnvironmentService } from '@/modules/guide/environment';
import { Route } from 'vue-router';
import VueI18n from 'vue-i18n';
import Error from './views/errors/Error.vue';
import { resolveFromCtx } from './fns/inject-resolver';
import { normalizeLanguage } from './i18n/normalize-language';

const detectLanguage = (route: Route, i18n: VueI18n) => {
  const lang = (
    (route.params && route.params.lang) ||
    'en'
  ).toLocaleLowerCase();
  if (i18n.locale !== lang) {
    i18n.locale = lang;
  }
};

const redirectToLanguage = (ctx: SsrCtx, lang: string): void => {
  const path = ctx.route.fullPath.replace(
    /^\/(web|ingame)\/([A-Z-]{2})/i,
    `/$1/${lang}`
  );
  ctx.redirect(path);
};

@Component({
  components: { Error },
})
export default class AppRoot extends Vue implements OnCreated, WithAsyncData {
  loaded = false;

  get hasError() {
    return this.$errorHandler?.error != null;
  }

  async asyncData(ctx: SsrCtx) {
    const currentLanguage = ctx.route?.params?.lang;
    const correctLanguage = normalizeLanguage(currentLanguage);
    if (correctLanguage != currentLanguage) {
      redirectToLanguage(ctx, correctLanguage);
      return;
    }
    detectLanguage(ctx.route, ctx.app.$i18n);

    const appModule = getModule(AppStore, ctx.store);
    if (appModule.appInitialized === ctx.app.$i18n.locale) {
      return;
    }
    const environmentService = resolveFromCtx<EnvironmentService>(
      'environmentService',
      ctx
    );
    await appModule.init({
      language: ctx.app.$i18n.locale,
      environmentService,
      redirect: ctx.redirect,
      i18n: ctx.app.$i18n,
    });
  }

  created() {
    detectLanguage(this.$route, this.$i18n);
  }
}
