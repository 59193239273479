import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { Section } from '@/modules/guide/environment';
import {
  FaqViewGroup,
  GuideViewGroup,
  HotNewsPreview,
  NewsPreview,
  LinkToArticle,
} from '@/modules/guide/articles';
import { PopularItem } from '@/modules/guide/popular';
import { Review } from '@/modules/guide/reviews';
import { SearchableArticle } from '@/modules/guide/search/searchable-article.model';
import { LanguageCode } from '@/core/system';

@Module({ name: 'guide', stateFactory: true, namespaced: true })
export class GuideStore extends VuexModule {
  items: {
    [segment: string]:
      | GuideViewGroup[]
      | FaqViewGroup[]
      | NewsPreview[]
      | LinkToArticle[];
  } = {};
  article: any = null;
  section: Section | null = null;
  popular: { [segment: string]: PopularItem[] } = {};
  resourceLanguage: LanguageCode = 'en';
  hotNews: HotNewsPreview[] = [];
  reviews: {
    [path: string]: Review;
  } = {};
  searchableArticles: SearchableArticle[] | null = null;

  @Mutation
  setItems(pl: {
    segment: string;
    items: GuideViewGroup[] | FaqViewGroup[] | NewsPreview[] | LinkToArticle[];
  }) {
    this.items = {
      ...this.items,
      [pl.segment]: pl.items,
    };
  }

  @Mutation
  setSection(section: Section | null) {
    this.section = section;
  }

  @Mutation
  setArticle(article: any) {
    this.article = article;
  }

  @Mutation
  setHotNews(news: HotNewsPreview[]) {
    this.hotNews = news;
  }

  @Mutation
  setReview(pl: { path: string; review: Review }) {
    this.reviews = {
      ...this.reviews,
      [pl.path]: pl.review,
    };
  }

  @Mutation
  setPopular(pl: { segment: string; items: PopularItem[] }) {
    this.popular[pl.segment] = pl.items;
  }

  @Mutation
  setSearchableArticles(pl: SearchableArticle[]) {
    this.searchableArticles = pl;
  }

  @Mutation
  resetResourceLanguage(lang: LanguageCode) {
    this.resourceLanguage = lang;
    this.popular = {};
    this.searchableArticles = null;
  }

  get sectionItems() {
    if (this.section == null || this.items[this.section.pathSegment] == null) {
      return [];
    }
    return this.items[this.section.pathSegment];
  }
}
