<template>
  <div
    :class="[
      'box',
      `box--${theme}`,
      {
        'box--with-icon': !!this.$slots['icon'],
      },
      opacity != null ? `box--opacity-${opacity}` : '',
    ]"
  >
    <div
      class="box__decorations-helper-first"
      v-if="!this.$slots['header'] && !disableTopDecoration"
    ></div>
    <div
      class="box__decorations-helper-second"
      v-if="!disableBottomDecoration"
    ></div>
    <slot name="icon"></slot>
    <slot name="header"></slot>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Box',
  props: {
    theme: {
      type: String,
      default: 'default',
      validator(val) {
        return [
          'default',
          'dark',
          'light',
          'half',
          'secondary',
          'none',
        ].includes(val);
      },
    },
    disableTopDecoration: {
      type: Boolean,
      default: false,
    },
    disableBottomDecoration: {
      type: Boolean,
      default: false,
    },
    opacity: Number,
  },
};
</script>

<style scoped></style>
