import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators';

@Module({ name: 'notifications', stateFactory: true, namespaced: true })
export class NotificationsStore extends VuexModule {
  notifications: { text: string }[] = [];

  @Mutation
  add(notification: { text: string }) {
    this.notifications = [...this.notifications, notification];
  }

  @Mutation
  remove(notification: { text: string }) {
    this.notifications = this.notifications.filter((x) => x !== notification);
  }

  @Action({ rawError: true })
  async notify(notification: { text: string }) {
    this.add(notification);
    setTimeout(() => {
      this.remove(notification);
    }, 3000);
  }
}
