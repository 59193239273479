import { AxiosInstance } from 'axios';
import {
  MessageId,
  PlayerId,
  TicketListItem,
  TicketNumber,
  TicketView,
} from './chat.model';
import { SendFeedbackCommand } from '../feedback/send-feedback.model';
import env from '@/env';
import { UploadedFile, UploadFilesResponse } from '@/modules/sts/common.models';

export class ChatService {
  constructor(private readonly $http: AxiosInstance) {}

  async getTickets(playerId: PlayerId) {
    const { data } = await this.$http.get<TicketListItem[]>(`/tickets/chat`, {
      params: { game: env.ticketsGame, playerId },
    });
    return data;
  }

  async getTicket(playerId: PlayerId, ticketNumber: TicketNumber) {
    const { data } = await this.$http.get<TicketView>(
      `/tickets/chat/${ticketNumber}`,
      {
        params: { game: env.ticketsGame, playerId },
      }
    );
    return data;
  }

  async reply(
    playerId: PlayerId,
    ticketNumber: TicketNumber,
    message: string,
    attachments: string[]
  ) {
    await this.$http.post(`/tickets/chat/${ticketNumber}`, {
      playerId,
      game: env.ticketsGame,
      message,
      attachments,
    });
  }

  async feedback(ticketNumber: TicketNumber, feedback: SendFeedbackCommand) {
    await this.$http.post(`/tickets/chat/${ticketNumber}/feedback`, {
      ...feedback,
      game: env.ticketsGame,
      ticketId: ticketNumber,
    });
  }

  async remove(playerId: PlayerId, ticketNumbers: TicketNumber[]) {
    await this.$http.delete(`/tickets/chat/`, {
      params: { ticketNumbers: ticketNumbers, game: env.ticketsGame, playerId },
    });
  }

  async rateMessage(
    ticketNumber: TicketNumber,
    messageId: MessageId,
    isHelpful: boolean
  ) {
    await this.$http.post(`/tickets/chat/rate-message`, {
      gameId: env.ticketsGame,
      ticketNumber,
      messageId,
      isHelpful,
    });
  }

  async upload(files: File[], onUploadProgressCb?: (percent: number) => void) {
    const form = new FormData();

    files.forEach((file) => {
      form.append('files', file);
    });

    const { data } = await this.$http.post<UploadFilesResponse>(
      '/tickets/chat/file',
      form,
      {
        onUploadProgress: function (progressEvent) {
          if (onUploadProgressCb) {
            const percentCompleted = progressEvent.loaded / progressEvent.total;
            onUploadProgressCb(percentCompleted);
          }
        },
        params: {
          game: env.ticketsGame,
        },
      }
    );
    return data;
  }

  getFilePreviewUrl(
    playerId: PlayerId,
    ticketNumber: TicketNumber,
    file: UploadedFile,
    absoluteUrl = false
  ) {
    return this.buildFileUrl(playerId, ticketNumber, file, true, absoluteUrl);
  }

  getFileUrl(
    playerId: PlayerId,
    ticketNumber: TicketNumber,
    file: UploadedFile,
    absoluteUrl = false
  ) {
    return this.buildFileUrl(playerId, ticketNumber, file, false, absoluteUrl);
  }

  private buildFileUrl(
    playerId: PlayerId,
    ticketNumber: TicketNumber,
    file: UploadedFile,
    preview: boolean,
    absoluteUrl: boolean
  ): string {
    const previewRoute = preview ? '/preview' : '';
    return absoluteUrl
      ? `${env.baseUrl}/api/tickets/chat/file${previewRoute}?playerId=${playerId}&game=${env.ticketsGame}&ticketNumber=${ticketNumber}&fileId=${file.id}`
      : `/api/tickets/chat/file${previewRoute}?playerId=${playerId}&game=${env.ticketsGame}&ticketNumber=${ticketNumber}&fileId=${file.id}`;
  }
}
