import { Guid } from '@/core/system';

export const sectionNames = ['guide', 'faq', 'tech', 'useful-articles'];

export interface SectionItem {
  id: Guid;
  name: string;
  path: string;
  ancestors: Guid[];
}

export interface GuideViewGroup {
  name: string;
  articles: LinkToArticle[];
}

export interface FaqViewGroup {
  name: string;
  iconUrl: string;
  articles: LinkToArticle[];
}

export interface LinkToArticle {
  link: string;
  title: string;
  icon: string;
}
export interface LinkToTechArticle extends LinkToArticle {
  icon: string;
}

export interface NewsPreview {
  link: string;
  title: string;
  description: string;
  date: string;
  thumbnails: Thumbnails;
}

export interface HotNewsPreview {
  item: NewsPreview;
  wide: boolean;
}

export interface Thumbnails {
  large: string;
  medium: string;
  small: string;
}
