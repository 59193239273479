import { AxiosInstance } from 'axios';
import { Guid } from '@/core/system';
import { SubscribingStatus } from './subscribing-status.model';
import { UnsubscribeCommand } from './unsubscribe.model';
import config from '@/env';

export class UnsubscribingService {
  constructor(private readonly $http: AxiosInstance) {}

  async getStatus(ticketId: Guid): Promise<SubscribingStatus> {
    const { data } = await this.$http.get<SubscribingStatus>(
      `/tickets/unsubscribe`,
      {
        params: { game: config.ticketsGame, ticketId },
      }
    );
    return data;
  }

  async unsubscribe(command: UnsubscribeCommand): Promise<boolean> {
    const response = await this.$http.post(`/tickets/unsubscribe`, command);
    return response.status === 200;
  }
}
