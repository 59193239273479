import { Dictionary } from '@/core/core.types';

export const makeGroups = <T>(items: T[], count: number): Array<T[]> => {
  const result = items.reduce((res, item, inx) => {
    const key = Math.floor(inx / count);
    res[key] = [...(res[key] || []), item];
    return res;
  }, {} as Dictionary<T[]>);
  return Object.values(result);
};
