<template>
  <div class="circle-with-title">
    <div class="circle-with-title__image"></div>
    <div class="circle-with-title__name">
      <span>
        <slot></slot>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CircleWithTitle',
  data() {
    return {
      multiline: false,
    };
  },
};
</script>

<style scoped></style>
