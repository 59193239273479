<template>
  <div :class="['sts-root', { 'sts-root--ingame': $layout().ingame }]">
    <router-view class="sts-web__content"></router-view>
  </div>
</template>

<script>
import { extend } from 'vee-validate';
import { required, email, min, max, mimes } from 'vee-validate/dist/rules.umd';
import { getModule } from 'vuex-module-decorators';
import { banEmails } from '@themes/v1/views/sts/validators';
import { AppStore } from '../../stores/app.store';
import { universalRedirect } from '../../fns/router';
import { waitInitializing } from '../../fns/store';

// No message specified.
extend('email', email);
extend('min', min);
extend('max', max);
extend('required', required);
extend('mimes', mimes);
extend('banEmails', banEmails);

export default {
  name: 'sts-root',
  data() {
    return {
      system: {},
    };
  },
  asyncData(ctx) {
    return waitInitializing(ctx).then(() => {
      const appStore = getModule(AppStore, ctx.store);
      if (!appStore.ticketsAppsIsAvailable) {
        universalRedirect(ctx, 'under-maintenance');
      }
    });
  },
};
</script>

<style></style>
