declare const process: {
  client: boolean;
  env: { [key: string]: string };
};

export default {
  isClient: !!(process as any).client,
  baseUrl: process.env.VUE_APP_BASE_URL,
  apiUrl: (process as any).client
    ? process.env.VUE_APP_API_URL
    : process.env.VUE_APP_API_SSR_URL,
  game: process.env.VUE_APP_GAME,
  ticketsGame: process.env.VUE_APP_TICKETS_GAME || process.env.VUE_APP_GAME,
  devtools: process.env.VUE_APP_DEVTOOLS === 'true',
  cookieLifetimeMinute: 60,
  gtagId:
    process.env.VUE_APP_GTAG_ID != null && process.env.VUE_APP_GTAG_ID != ''
      ? process.env.VUE_APP_GTAG_ID
      : null,
  yandexMetricaId:
    process.env.VUE_APP_YANDEXMETRICA_ID != null &&
    process.env.VUE_APP_YANDEXMETRICA_ID != ''
      ? process.env.VUE_APP_YANDEXMETRICA_ID
      : null,
  supportAttachments: [
    'image/*',
    'video/*',
    'application/pdf',
    'application/msword',
    'text/plain',
    'text/markdown',
    'application/vnd.*',
  ],
  supportAttachmentsExt: [
    'jpg',
    'jpeg',
    'png',
    'pdf',
    'bmp',
    'tga',
    'avi',
    'mp4',
    'm4v',
    'mov',
    'mkv',
    'flv',
    'f4v',
    'wmv',
    'txt',
    'doc',
    'docx',
  ],
  maxAttachment: 1024 * 1024 * 100,
  isAutomationTestsEnabled: process.env.VUE_APP_AUTOMATION_TESTS === 'true',
  relatedDomains:
    process.env.VUE_APP_RELATED_DOMAINS?.split(',').filter(
      (x) => x.trim().length > 0
    ) ?? [],
};
