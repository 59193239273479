import { Module, VuexModule, Mutation } from 'vuex-module-decorators';
import { TemporaryProblemViewModel } from '@/modules/sts/temporary-problems';
import { Dictionary } from '@/core/core.types';
import { Guid } from '@/core/system';

@Module({ name: 'problems', stateFactory: true, namespaced: true })
export class ProblemsStore extends VuexModule {
  problems: Dictionary<TemporaryProblemViewModel> = {};
  ids: Guid[] = [];
  unsubscribedProblem: TemporaryProblemViewModel | null = null;

  @Mutation
  set(problems: TemporaryProblemViewModel[]) {
    problems.forEach((problem) => {
      this.problems[problem.id] = problem;
      if (!this.ids.includes(problem.id)) {
        this.ids = [...this.ids, problem.id];
      }
    });
    this.problems = { ...this.problems };
  }

  @Mutation
  clear() {
    this.problems = {};
    this.ids = [];
  }

  @Mutation
  setUnsubscribedProblem(problem: TemporaryProblemViewModel) {
    this.unsubscribedProblem = problem;
  }

  get list() {
    return this.ids.map((id) => this.problems[id]);
  }
}
