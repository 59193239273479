import {
  IReqInterceptor,
  IResInterceptor,
} from '@/core/http/http-client.types';
import { PlayerStore } from '../../stores/player.store';
import { getModule } from 'vuex-module-decorators';

export const playerAuthReqInterceptor: IReqInterceptor = (store) => ({
  onFulfilled(req) {
    const playerStore = getModule(PlayerStore, store);
    if (!playerStore.isAuth) {
      return req;
    }

    req.headers['Authorization'] = `Bearer ${playerStore.token}`;
    return req;
  },
});

export const playerAuthResInterceptor: IResInterceptor = (store) => ({
  onFulfilled(res) {
    return res;
  },
  onRejected(err) {
    if (err.response?.status === 401) {
      const playerStore = getModule(PlayerStore, store);
      playerStore.setAuth(false);
      playerStore.setToken('');
    }
    return Promise.reject(err);
  },
});
