export default {
  css: {
    normal: 'extract',
    vue: 'extract',
  },
  plugins: [
    '@uvue/core/plugins/asyncData',
    [
      '@uvue/core/plugins/vuex',
      {
        onHttpRequest: true,
        fetch: true,
      },
    ],
    '@uvue/core/plugins/errorHandler',
    '@/plugins/errorClear.plugin.js',
    '@uvue/core/plugins/prefetch',
    '@uvue/core/plugins/middlewares',
  ],
};
