import { NumberFormats } from 'vue-i18n';

export const numberFormats: NumberFormats = {
  en: {
    decimal: {
      useGrouping: true,
    },
  },
  es: {
    decimal: {
      useGrouping: false,
    },
  },
  de: {
    decimal: {
      useGrouping: true,
    },
  },
  fr: {
    decimal: {
      useGrouping: true,
    },
  },
  it: {
    decimal: {
      useGrouping: false,
    },
  },
  ja: {
    decimal: {
      useGrouping: true,
    },
  },
  ko: {
    decimal: {
      useGrouping: true,
    },
  },
  ru: {
    decimal: {
      useGrouping: true,
    },
  },
  tr: {
    decimal: {
      useGrouping: true,
    },
  },
};
