import { SsrCtx } from '@/core/vue.types';
import { getModule } from 'vuex-module-decorators';
import { AppStore } from '../stores/app.store';

export const waitInitializing = function (ctx: SsrCtx) {
  const appStore = getModule(AppStore, ctx.store);
  if (appStore.appInitialized !== '') {
    return Promise.resolve(true);
  }
  return new Promise((resolve) => {
    const unsubscribe = ctx.store.subscribe((mutation) => {
      if (mutation.type === 'app/markAsInitialized') {
        unsubscribe();
        resolve(true);
      }
    });
  });
};
