<template>
  <div
    :class="['bg-image', border && 'bg-image--with-border', fallbackImageClass]"
    :style="style"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'BgImage',
  props: {
    url: String,
    border: {
      type: Boolean,
      default: true,
    },
    fallbackImageClass: String,
  },
  computed: {
    style() {
      return this.url ? { backgroundImage: `url(${this.url})` } : {};
    },
  },
};
</script>

<style scoped></style>
