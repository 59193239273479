import { SsrCtx } from '@/core/vue.types';
import { getModule } from 'vuex-module-decorators';
import { PlayerStore } from '@/themes/v1/stores/player.store';
import { playerService } from '@/modules/player.service';
import env from '@/env';

// TODO: Danger!!! Shit code

function parseCookies(request) {
  const list = {},
    rc = request.headers.cookie;

  rc &&
    rc.split(';').forEach(function (cookie) {
      const parts = cookie.split('=');
      list[parts.shift().trim()] = decodeURI(parts.join('='));
    });

  return list;
}

function makeCookieString(key: string, value: string, expired: Date) {
  return `${key}=${encodeURI(value)}; Expires=${expired.toUTCString()}; Path=/`;
}

function makeCookieExpiredString(key: string, value: string) {
  return `${key}=${value}; Expires=Thu, 01 Jan 1970 00:00:00 GMT; Path=/`;
}

export const clearAuthCookies = (ctx: SsrCtx) => {
  if (process.client) return;
  const playerStore = getModule(PlayerStore, ctx.store);
  ctx.res.setHeader('Set-Cookie', [
    makeCookieExpiredString('token', ''),
    ...playerStore.playerKeys.map((key) => makeCookieExpiredString(key, '')),
  ]);
};

export const authMiddleware = () => async (ctx: SsrCtx) => {
  const playerStore = getModule(PlayerStore, ctx.store);

  if (process.server && !playerStore.isAuth) {
    const cookies = parseCookies(ctx.req);
    if (cookies['token']) {
      playerStore.init(cookies);
      playerStore.setToken(cookies['token']);
    } else if (!playerStore.isAuth) {
      clearAuthCookies(ctx);
    }
  }
};
