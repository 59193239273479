import { nearestMiddleIndexOf } from '../fns/string-utils';

export default {
  inserted: function (el: HTMLElement) {
    const text = el.innerText.trim();
    if (text != null && text.length >= 16 && text.includes(' ')) {
      const index = nearestMiddleIndexOf(text, (char) => char === ' ');
      if (index >= 0) {
        const newString =
          text.substring(0, index) + '\n' + text.substring(index + 1);
        el.innerText = newString;
        el.classList.add('multiline');
      }
    }
  },
};
