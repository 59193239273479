export function parseError(error) {
  if (error == null) return 'null';
  if (typeof error === 'string') return error;
  if (error && error.message) return error.message;
  if (typeof error === 'object')
    return Object.entries(error)
      .map(([key, value]) => `${key}: ${value}`)
      .join(', ');
  return error.toString();
}

export function parseErrorStack(error) {
  return (error && error.stack) || '';
}
