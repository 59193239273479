













import { Component, Vue } from 'vue-property-decorator';
import SectionCircleLink from '@/themes/v1/components/SectionCircleLink.vue';
import { getModule } from 'vuex-module-decorators';
import { AppStore } from '../stores/app.store';
import CircleSectionGroup from '@/themes/v1/components/CircleSectionGroup.vue';
import { makePathWithLangAndPlatform } from '../fns/router';
import { DEFAULT_LANGUAGE } from '../core/config/defaults';
import Multiline from '@/themes/v1/directives/multiline-text';

@Component({
  components: { CircleSectionGroup, SectionCircleLink },
  directives: { Multiline },
})
export default class FaqAndGuideLinks extends Vue {
  private readonly appStore = getModule(AppStore, this.$store);

  get sections() {
    const sections = ['guide', 'faq']
      .map((key) => this.appStore.sections[key])
      .filter(Boolean)
      .map((x) => ({
        key: x.pathSegment,
        path: makePathWithLangAndPlatform(
          this.$route,
          x.pathSegment,
          this.$i18n.locale || DEFAULT_LANGUAGE
        ),
        name: x.name,
      }));
    return sections;
  }
}
