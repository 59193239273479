import { PluginObject } from 'vue';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface LayoutPlugin {
  ingame: boolean;
  web: boolean;
}

export const LayoutPlugin: PluginObject<void> = {
  install(Vue) {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    Vue.prototype.$layout = function () {
      return {
        ingame: this.$route.params.platform === 'ingame',
        web:
          this.$route.params.platform === 'web' ||
          this.$route.params.platform == null,
      } as any;
    };
  },
};
