<template>
  <portal to="layout-header">
    <slot></slot>
  </portal>
</template>

<script>
import { Portal } from 'portal-vue';

export default {
  name: 'LayoutHeaderExtends',
  components: {
    Portal,
  },
};
</script>

<style scoped></style>
