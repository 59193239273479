













import { Component, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { NotificationsStore } from '../../stores/notifications.store';

@Component
export default class Notifications extends Vue {
  private notificationsStore = getModule(NotificationsStore, this.$store);

  get messages() {
    return this.notificationsStore.notifications;
  }

  onClick(item) {
    this.notificationsStore.remove(item);
  }
}
