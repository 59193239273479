import { SsrCtx } from '@/core/vue.types';
import { getModule } from 'vuex-module-decorators';
import { SystemStore } from '@/core/system/system.store';
import { getDeviceInfo } from '@/core/system/device-detector';
import { Devices, UiStore } from '@/themes/v1/stores/ui.store';

export const makeDeviceDetectorMiddleware = () => (ctx: SsrCtx) => {
  if (process.server) {
    const systemStore = getModule(SystemStore, ctx.store);
    const deviceInfo = getDeviceInfo(ctx.req);
    systemStore.setDevice(deviceInfo);
    if (deviceInfo.isMobileOrTablet) {
      const uiStore = getModule(UiStore, ctx.store);
      if (deviceInfo.isMobile) {
        uiStore.setDevice(Devices.xs)
      } else if (deviceInfo.isTablet) {
        uiStore.setDevice(Devices.md);
      }
    }
  }
};
