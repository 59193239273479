import Vue from 'vue';
import VueMeta from 'vue-meta';
import Component from 'vue-class-component';
//import { AppFactory } from '@/core/core.types';

// import './registerServiceWorker';
import '@uvue/core/vueclass';

Vue.use(VueMeta, {});
Component.registerHooks(['metaInfo']);

// eslint-disable-next-line @typescript-eslint/no-var-requires
const project = require(`./projects/${process.env.VUE_APP_GAME}`);

project.setupPlugins();
export default () => {
  return new Vue(project.createApp());
};
