import { Module, VuexModule, Mutation } from 'vuex-module-decorators';
import {
  DeviceInfo,
  ErrorInfo,
  ErrorWithStatus,
} from '@/core/system/system.models';
@Module({ name: 'system', stateFactory: true, namespaced: true })
export class SystemStore extends VuexModule {
  device: DeviceInfo = {} as any;
  error: ErrorWithStatus | null = null;

  @Mutation
  setDevice(pl: DeviceInfo) {
    this.device = pl;
  }

  @Mutation
  setError(error: ErrorWithStatus) {
    this.error = error;
  }

  @Mutation
  resetError() {
    this.error = null;
  }
}
