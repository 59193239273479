import { PluginObject } from 'vue';
import { Route } from 'vue-router';
import { makePathWithLangAndPlatform } from '@/themes/v1/fns/router';
import { LanguageCode } from '@/core/system';

export interface RouterHelper {
  withPlatformAndLang: (
    route: Route,
    path: string,
    fallbackLanguage?: LanguageCode
  ) => string;
}

export const RouterHelperPlugin: PluginObject<void> = {
  install(Vue) {
    Vue.prototype.$routerHelper = {
      withPlatformAndLang: makePathWithLangAndPlatform,
    } as RouterHelper;
  },
};
