




import { Vue, Component, Watch } from 'vue-property-decorator';
import { SsrCtx } from '@/core/vue.types';
import { getModule } from 'vuex-module-decorators';
import { PlayerStore } from '@/themes/v1/stores/player.store';
import {
  replaceWithLangAndPlatform,
  universalRedirect,
} from '@/themes/v1/fns/router';

@Component
export default class AuthGuard extends Vue {
  playerStore = getModule(PlayerStore, this.$store);

  async asyncData(ctx: SsrCtx) {
    const playerStore = getModule(PlayerStore, ctx.store);
    if (!playerStore.isAuth) {
      await universalRedirect(ctx, 'problems');
      return {};
    }
    return {};
  }

  @Watch('playerStore.isAuth')
  onAuthChange(newValue: boolean, oldValue: boolean) {
    if (!newValue && oldValue) {
      replaceWithLangAndPlatform(this.$router, '/tickets/auth-expired');
    }
  }
}
