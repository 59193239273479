import { Guid } from '@/core/system';

export enum SubscribingResult {
  Accepted = 'accepted',
  AlreadySubscribed = 'alreadySubscribed',
}

export enum AllowUnsubscribeStatus {
  Allowed = 'allowed',
  NotFound = 'notFound',
  AlreadyUnsubscribed = 'alreadyUnsubscribed',
}

export enum TemporaryProblemStatus {
  Open = 'opened',
  Closed = 'closed',
}

export interface TemporaryProblemDescriptionViewModel {
  title: string;
  problemStatusText: string;
  content: string;
}

export interface TemporaryProblemMessageViewModel {
  id: Guid;
  problemStatusText: string;
  content: string;
  dateCreated: string;
  dateSent: string;
  active: boolean;
}

export interface TemporaryProblemViewModel {
  id: Guid;
  dateOpened: string;
  description: TemporaryProblemDescriptionViewModel;
  messages: TemporaryProblemMessageViewModel[];
  status: TemporaryProblemStatus;
}
