<template>
  <portal to="layout-shadow">
    <div class="layout-shadow" @click="$emit('click')"></div>
  </portal>
</template>

<script>
import { Portal } from 'portal-vue';

export default {
  name: 'LayoutShadow',
  components: { Portal },
};
</script>

<style scoped></style>
