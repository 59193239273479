










import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class Spinner extends Vue {
  @Prop({
    type: String,
    default: 'block',
    validator(val) {
      return ['block', 'cover'].includes(val);
    },
  })
  type!: string;

  @Prop({
    type: String,
    default: '100px',
  })
  height!: string;

  @Prop({
    type: Boolean,
    default: true,
  })
  mask!: boolean;
}
