import { Language } from '@/modules/guide/environment';
import { IVueI18n } from 'vue-i18n';

const codes = ['en', 'ru', 'de', 'fr', 'it', 'es', 'ja', 'ko', 'tr'];

export default function (i18n: IVueI18n): Language[] {
  return codes.map((locale) => ({
    code: locale,
    name: i18n.t('common.language native', locale) as string,
  }));
}
