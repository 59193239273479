import { Module, VuexModule, Mutation } from 'vuex-module-decorators';

@Module({ name: 'chat', stateFactory: true, namespaced: true })
export class ChatStore extends VuexModule {
  notification: { title: string; text: string } | null = null;

  @Mutation
  setNotification(notification: { title: string; text: string } | null) {
    this.notification = notification;
  }
}
