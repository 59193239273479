import { AxiosInstance } from 'axios';
import { Environment } from './environment.model';
import { GameId, LanguageCode } from '@/core/system';

export class EnvironmentService {
  constructor(private readonly $http: AxiosInstance) {}

  async get(game: GameId, language: LanguageCode): Promise<Environment> {
    const { data } = await this.$http.get<Environment>(`/environment`, {
      params: { game, language },
    });
    return data;
  }
}
