import { IReqInterceptor } from '@/core/http/http-client.types';
import { AppStore } from '../../stores/app.store';
import { getModule } from 'vuex-module-decorators';

export const previewModeInterceptor: IReqInterceptor = (store) => ({
  onFulfilled(config) {
    const appStore = getModule(AppStore, store);
    const previewVersion = appStore.previewVersion;
    if (previewVersion == null) {
      return config;
    }
    config.headers.common['Plr-Preview-Version'] = previewVersion;
    return config;
  },
});
