import { PluginObject } from 'vue';
import { getModule } from 'vuex-module-decorators';
import { Devices, UiStore } from '../stores/ui.store';

export type BreakpointsFn = (value: Devices | Devices[]) => boolean;

export const BreakpointsPlugin: PluginObject<void> = {
  install(Vue) {
    Vue.prototype.$bps = function (value: Devices | Devices[]) {
      const arr = value instanceof Array ? value : [value];
      const store = getModule(UiStore, this.$store);
      return arr.includes(store.device);
    };
  },
};
