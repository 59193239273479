














import { Component, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { AppStore } from '../stores/app.store';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const NoSsr = require('vue-no-ssr');

@Component({
  components: {
    'no-ssr': NoSsr,
  },
})
export default class InGameHeader extends Vue {
  appModule = getModule(AppStore, this.$store);
}
