<template>
  <header>
    <HeaderPanel :disabled="$route.meta.isPreview"></HeaderPanel>
  </header>
</template>

<script>
import HeaderPanel from '@themes/v1/layouts/HeaderPanel';

export default {
  name: 'Header',
  props: ['language', 'languages', 'links'],
  components: {
    HeaderPanel,
  },
};
</script>
