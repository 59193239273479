import { AxiosInstance } from 'axios';
import {
  SectionItem,
  GuideViewGroup,
  FaqViewGroup,
  NewsPreview,
  LinkToTechArticle,
} from './section-item.model';
import { GameId, LanguageCode } from '@/core/system';

export class ArticleService {
  constructor(private readonly $http: AxiosInstance) {}

  async getGuideViewItems(
    game: GameId,
    language: LanguageCode,
    segment: string
  ): Promise<GuideViewGroup[]> {
    const { data } = await this.$http.get<GuideViewGroup[]>(`/section/guide`, {
      params: { game, language, segment },
    });
    return data;
  }

  async getFaqViewItems(
    game: GameId,
    language: LanguageCode,
    segment: string
  ): Promise<FaqViewGroup[]> {
    const { data } = await this.$http.get<FaqViewGroup[]>(`/section/faq`, {
      params: { game, language, segment },
    });
    return data;
  }

  async getTechIssuesViewItems(
    game: GameId,
    language: LanguageCode,
    segment: string
  ): Promise<LinkToTechArticle[]> {
    const { data } = await this.$http.get<LinkToTechArticle[]>(
      `/section/tech`,
      {
        params: { game, language, segment },
      }
    );
    return data;
  }

  async getItem(
    game: GameId,
    language: LanguageCode,
    path: string
  ): Promise<any> {
    const { data } = await this.$http.get<any>(`/article`, {
      params: { game, language, path },
    });
    return data;
  }

  async getLastNews(
    game: GameId,
    language: LanguageCode,
    segment: string,
    count: number
  ): Promise<NewsPreview[]> {
    const { data } = await this.$http.get<any>(`/section/news/last`, {
      params: { game, language, segment, count },
    });
    return data;
  }
}
