import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { LanguageCode } from '@/core/system';
import {
  ApplicationLinks,
  Environment,
  EnvironmentService,
  FooterLinks,
  Language,
  PageMetadata,
  Section,
} from '@/modules/guide/environment';
import config from '@/env';
import { RawLocation } from 'vue-router';
import { StatusCodes } from 'http-status-codes';
import VueI18n, { IVueI18n } from 'vue-i18n';
import fallbackLanguages from '@/themes/v1/core/config/fallback-languages';

@Module({ name: 'app', stateFactory: true, namespaced: true })
export class AppStore extends VuexModule {
  loading = false;
  showMenu = false;
  languages: Language[] = [];
  footerLinks: FooterLinks = {
    socialLinks: [],
    additionalLinks: [],
  };
  foreignLinks: Array<{ type: string; text: string; uri: string }> = [];
  sections: { [pathSegment: string]: Section } = {};
  previewVersion: string | null = null;
  appInitialized = '';
  ticketsAppsIsAvailable = true;
  mainPageMetadata: PageMetadata | null = null;

  get sectionsList() {
    return Object.values(this.sections);
  }

  @Mutation
  toggleMenu(status: boolean) {
    this.showMenu = status;
  }

  @Mutation
  setLoading(status: boolean) {
    this.loading = status;
  }

  @Mutation
  markAsInitialized(lang: string) {
    this.appInitialized = lang;
  }

  @Mutation
  private setData(payload: {
    languages: Language[];
    appLinks: ApplicationLinks;
    sections: Section[];
    ticketsAppsIsAvailable: boolean;
    mainPageMetadata: PageMetadata;
  }) {
    this.languages = payload.languages;
    this.footerLinks = payload.appLinks?.footerLinks;
    this.foreignLinks = payload.appLinks?.foreignLinks;
    this.ticketsAppsIsAvailable = payload.ticketsAppsIsAvailable;
    this.mainPageMetadata = payload.mainPageMetadata;
    this.sections = payload.sections.reduce((res, section) => {
      return {
        ...res,
        [section.pathSegment]: section,
      };
    }, {} as any);
  }

  @Mutation
  setPreviewMode(version: string) {
    this.previewVersion = version;
  }

  @Action({ rawError: true })
  async init({
    language,
    environmentService,
    redirect,
    i18n,
  }: {
    language: LanguageCode;
    environmentService: EnvironmentService;
    redirect: (location: RawLocation, code?: StatusCodes) => void;
    i18n: VueI18n & IVueI18n;
  }) {
    try {
      this.context.commit('setLoading', true);
      const env: Environment = await environmentService.get(
        config.game,
        language
      );
      this.context.commit('setData', {
        languages: env.languages,
        appLinks: env.applicationLinks,
        sections: env.sections,
        ticketsAppsIsAvailable: env.isTicketsAppsEnabled,
        mainPageMetadata: env.mainPageMetadata,
      });
      this.context.commit('markAsInitialized', language);
    } catch (err) {
      if (err && err.response) {
        if (err.response.status === 404 && language !== 'en') {
          redirect('/web/en');
        } else {
          this.context.commit('setData', {
            languages: fallbackLanguages(i18n),
            appLinks: {
              footerLinks: { socialLinks: [], additionalLinks: [] },
              foreignLinks: [],
            },
            sections: [],
            ticketsAppsIsAvailable: true,
            mainPageMetadata: { title: '', description: '', keywords: '' },
          });
          this.context.commit('markAsInitialized', language);
        }
      }
    } finally {
      this.context.commit('setLoading', false);
    }
  }
}
