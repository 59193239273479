export const nearestMiddleIndexOf = (input: string, func: (char: string, index: number, source: string)=>boolean) => {
  const middle = Math.round(input.length / 2);
  const result = [...input]
    .map((char, index) => ({ char, index }))
    .filter((item, index) => func(item.char, index, input))
    .map(item => ({ index: item.index, distance: Math.abs(middle - item.index) }))
    .sort((a, b) => a.distance - b.distance);
  if (result.length === 0) {
    return -1;
  }
  return result[0].index;
}