<template>
  <StyledSelect
    :disabled="disabled"
    :value="language"
    :options="options"
    @change="changeLanguage($event)"
  >
    <template slot="selected">{{ $t('common.language short') }}</template>
  </StyledSelect>
</template>

<script>
import StyledSelect from '../components/StyledSelect';

export default {
  name: 'LanguageSelector',
  props: ['language', 'languages', 'disabled'],
  components: {
    StyledSelect,
  },
  computed: {
    options() {
      return this.languages.map((l) => ({
        value: l.code,
        title: l.name,
        icon: 'flag-',
      }));
    },
  },
  methods: {
    changeLanguage(lang) {
      this.$emit('changeLanguage', lang);
    },
  },
};
</script>
