

























import { Component, Vue } from 'vue-property-decorator';
import Sidemenu from '@/themes/v1/layouts/Sidemenu.vue';
import Header from '@/themes/v1/layouts/Header.vue';
import Footer from '@/themes/v1/layouts/Footer.vue';
import DrawerMenu from '@/themes/v1/layouts/DrawerMenu.vue';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const NoSsr = require('vue-no-ssr');

@Component({
  name: 'WebLayout',
  components: {
    DrawerMenu,
    Sidemenu,
    Header,
    Footer,
    NoSsr,
  },
})
export default class WebLayout extends Vue {
  get fullscreen() {
    return this.$route?.meta?.webOptions?.fullscreen === true;
  }
}
