import { AxiosInstance } from 'axios';
import { PopularItem } from './popular.model';
import { GameId, LanguageCode } from '@/core/system';

export class PopularService {
  constructor(private readonly $http: AxiosInstance) {}

  async get(
    game: GameId,
    language: LanguageCode,
    segment: string
  ): Promise<PopularItem[]> {
    const { data } = await this.$http.get<PopularItem[]>(`/section/popular`, {
      params: { game, language, segment },
    });
    return data;
  }
}
