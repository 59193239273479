



import { Component, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import { SsrCtx, WithAsyncData } from '@/core/vue.types';
import Error from './Error.vue';
import { ErrorWithStatus, SystemStore } from '@/core/system';

@Component({
  components: {
    Error,
  },
})
export default class Error404Page extends Vue implements WithAsyncData {
  async asyncData(ctx: SsrCtx) {
    const systemStore = getModule(SystemStore, ctx.store);
    const details = {
      path: ctx.route.fullPath,
    };
    systemStore.setError(new ErrorWithStatus('Unknown route', 404, details));
  }
}
