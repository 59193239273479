import { Module, VuexModule, Mutation } from 'vuex-module-decorators';

import { TicketCreatorOptions } from '@/modules/sts/creator/creator-options.model';
import { LanguageCode } from '@/core/system';
import { RateMessagePageStatus } from '@/modules/sts/chat/rate-message-status';

@Module({ name: 'tickets', stateFactory: true, namespaced: true })
export class TicketsStore extends VuexModule {
  language: LanguageCode = '';
  options: TicketCreatorOptions = {
    languages: [],
    reasons: [],
    platforms: [],
    languagesWithReasonDetector: [],
  };
  rateMessagePageValue = RateMessagePageStatus.Undefined;

  @Mutation
  setOptions({
    lang,
    options,
  }: {
    lang: LanguageCode;
    options: TicketCreatorOptions;
  }) {
    this.language = lang;
    this.options = options;
  }

  get platforms() {
    return this.options && this.options.platforms;
  }

  @Mutation
  setRateMessagePageValue(value: RateMessagePageStatus) {
    this.rateMessagePageValue = value;
  }
}
