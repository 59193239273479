import { RouteConfig } from 'vue-router';

export const withWrapper = (
  wrapper: any,
  routesOrRoute: RouteConfig[] | RouteConfig
): RouteConfig[] => {
  const routes = Array.isArray(routesOrRoute) ? routesOrRoute : [routesOrRoute];
  return routes.map((c) => {
    const { path, ...other } = c;
    return {
      path,
      component: wrapper,
      children: [
        {
          path: '',
          ...other,
        },
      ],
    } as RouteConfig;
  });
};
