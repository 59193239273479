import { RouteConfig } from 'vue-router';
import StsRoot from './StsRoot.vue';
import { withWrapper } from '@/core/router';
import AuthGuard from './AuthGuard.vue';
import { PreviewMeta } from '@/themes/v1/layouts/PreviewMeta';

export const stsRoutes: RouteConfig[] = withWrapper(StsRoot, [
  {
    path: 'tickets/create',
    component: () => import('./tickets/Create.vue'),
    meta: {
      inGameOptions: {
        hideHeader: true,
        hideFooter: true,
      },
    },
  },
  {
    path: 'tickets/success',
    component: () => import('./Success.vue'),
    props: {
      title: 'phrases.request accepted',
      subtitle: 'phrases.you can find more answers here',
      text: 'pages.successTicketCreation.message',
    },
    meta: {
      inGameOptions: {
        hideHeader: true,
        hideFooter: true,
      },
    },
  },
  {
    path: 'tickets/failed',
    component: () => import('./InfoPage.vue'),
    props: {
      title: 'pages.createTicket.errors.title',
      text: 'pages.createTicket.errors.message',
    },
    meta: {
      inGameOptions: {
        hideHeader: true,
        hideFooter: true,
      },
    },
  },
  {
    path: 'tickets/waiting',
    component: () => import('./tickets/Waiting.vue'),
    meta: {
      inGameOptions: {
        hideHeader: true,
        hideFooter: true,
        fullscreen: true,
      },
    },
  },
  {
    path: 'tickets/auth-expired',
    component: () => import('./tickets/AuthExpired.vue'),
    meta: {
      inGameOptions: {
        hideHeader: true,
        hideFooter: true,
      },
      doNotTrackPage: true,
    },
  },
  {
    path: 'tickets/message-rate/:ticket/:messageId/:helpful',
    component: () => import('./tickets/MessageRatePage.vue'),
  },

  ...withWrapper(AuthGuard, [
    {
      path: 'tickets',
      component: () => import('./tickets/List.vue'),
      meta: {
        inGameOptions: {
          hideHeader: true,
          hideFooter: true,
          fullscreen: true,
        },
        webOptions: {
          fullscreen: true,
        },
      },
    },
    {
      path: 'tickets/:ticket',
      component: () => import('./tickets/Discussion.vue'),
      meta: {
        inGameOptions: {
          hideHeader: true,
          hideFooter: true,
          fullscreen: true,
        },
        webOptions: {
          fullscreen: true,
        },
      },
    },
    {
      path: 'requests/removeAccount',
      component: () => import('./tickets/RemoveAccount.vue'),
      meta: {
        inGameOptions: {
          hideHeader: true,
          hideFooter: true,
        },
      },
    },
  ]),
  {
    path: 'unsubscribe/success',
    component: () => import('./InfoPage.vue'),
    props: {
      title: 'pages.unsubscribe.success.title',
      text: 'pages.unsubscribe.success.text',
    },
  },
  {
    path: 'unsubscribe/failed',
    component: () => import('./InfoPage.vue'),
    props: {
      title: 'pages.unsubscribe.errors.title',
      text: 'pages.unsubscribe.errors.notFound',
    },
  },
  {
    path: 'unsubscribe/notFound',
    component: () => import('./InfoPage.vue'),
    props: {
      title: 'pages.unsubscribe.errors.title',
      text: 'pages.unsubscribe.errors.notFound',
    },
  },
  {
    path: 'unsubscribe/unsubscribed',
    component: () => import('./InfoPage.vue'),
    props: {
      title: 'pages.unsubscribe.errors.title',
      text: 'pages.unsubscribe.errors.already',
    },
  },
  {
    path: 'unsubscribe/:ticket',
    component: () => import('./unsubscribe/Unsubscribe.vue'),
  },
  {
    path: 'feedback/success',
    component: () => import('./Success.vue'),
    props: {
      title: 'pages.feedback.success.title',
      text: 'pages.feedback.success.text',
    },
  },
  {
    path: 'feedback/outdated',
    component: () => import('./InfoPage.vue'),
    props: {
      title: 'phrases.attention',
      text: 'pages.feedback.errors.outdated',
    },
  },
  {
    path: 'feedback/alreadyRated',
    component: () => import('./InfoPage.vue'),
    props: {
      title: 'phrases.attention',
      text: 'pages.feedback.errors.alreadyRated',
    },
  },
  {
    path: 'feedback/wasReopened',
    component: () => import('./InfoPage.vue'),
    props: {
      title: 'phrases.attention',
      text: 'pages.feedback.errors.reopened',
    },
  },
  {
    path: 'feedback/rejected',
    component: () => import('./InfoPage.vue'),
    props: {
      title: 'phrases.attention',
      text: 'pages.feedback.errors.rejected',
    },
  },
  {
    path: 'feedback/:feedbackId',
    component: () => import('./feedback/Feedback.vue'),
  },
  {
    path: 'problems',
    component: () => import('./problems/GamePlatform.vue'),
  },
  {
    path: 'problems/unsubscribe/:problemId',
    component: () => import('./problems/Unsubscribe.vue'),
  },
  {
    path: 'problems/preview/:previewId',
    component: () => import('./problems/Preview.vue'),
    meta: {
      isPreview: true,
    } as PreviewMeta,
  },
  {
    path: 'problems/:gamePlatform',
    component: () => import('./problems/List.vue'),
    meta: {
      inGameOptions: {
        hideHeader: true,
        hideFooter: true,
      },
    },
  },
  {
    path: 'problems/:gamePlatform/:problemId',
    component: () => import('./problems/View.vue'),
    meta: {
      breadcrumbs: 'problems',
      inGameOptions: {
        hideHeader: true,
        hideFooter: true,
      },
    },
  },
]);
