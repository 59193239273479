import { RouteConfig } from 'vue-router';

import LayoutGuard from './layouts/Layout.vue';
import Root from './app.root.vue';
import { guideRoutes } from './views/guide';
import { stsRoutes } from './views/sts';

import Error404Page from './views/errors/Error404Page.vue';
import UnderMaintenancePage from './views/errors/UnderMaintenancePage.vue';
import { authMiddleware } from '@/themes/v1/middlewares/auth.middleware';
import { makeDeviceDetectorMiddleware } from '@/core/system';

export const routes: Array<RouteConfig> = [
  {
    path: '/:platform/:lang',
    component: Root,
    meta: {
      middlewares: [makeDeviceDetectorMiddleware(), authMiddleware()],
    },
    children: [
      {
        path: '',
        component: LayoutGuard,
        children: [...stsRoutes, ...guideRoutes],
      },
      {
        path: 'under-maintenance',
        component: UnderMaintenancePage,
      },
      {
        path: '**',
        component: Error404Page,
      },
    ],
  },

  {
    path: '',
    redirect: '/web/en',
  },
  {
    path: '**',
    component: Root,
    children: [
      {
        path: '',
        component: Error404Page,
      },
    ],
  },
];
