import { RouteConfig } from 'vue-router';
import Home from './home/Home.vue';
import GuideRoot from './guide-root.vue';
import GuideSearchWrapper from './guide-search-wrapper.vue';

export const guideRoutes: RouteConfig[] = [
  {
    path: '',
    component: GuideRoot,
    children: [
      {
        path: '',
        component: GuideSearchWrapper,
        children: [
          {
            path: '',
            component: Home,
          },
          {
            path: 'search',
            component: () => import('./search/Search.vue'),
          },
          {
            path: '',
            component: () => import('./section/Section.vue'),
            children: [
              {
                path: 'guide',
                component: () => import('./section/GuideSection.vue'),
              },
              {
                path: 'faq*',
                component: () => import('./section/FaqSection.vue'),
              },
              {
                path: 'useful-articles',
                component: () => import('./section/NewsSection.vue'),
              },
              {
                path: 'tech',
                component: () => import('./section/TechIssuesSection.vue'),
              },
            ],
          },
          {
            path: ':section/*',
            component: () => import('./article/ArticleView.vue'),
          },
        ],
      },
    ],
  },
];
