<template>
  <span v-html="template"></span>
</template>
<script>
export default {
  computed: {
    template() {
      return this.text.replace(this.queryRegex, '<em>$1</em>');
    },
    queryRegex() {
      return new RegExp(`(${this.query})`, 'ig');
    },
  },
  props: {
    text: {
      type: String,
      required: true,
    },
    query: {
      type: String,
      required: true,
    },
  },
};
</script>
