import { Route } from 'vue-router';
import { SsrCtx } from '@/core/vue.types';
import { VueRouter } from 'vue-router/types/router';
import {
  DEFAULT_LANGUAGE,
  DEFAULT_PLATFORM,
} from '@/themes/v1/core/config/defaults';
import { LanguageCode } from '@/core/system';
import { sectionNames } from '@/modules/guide/articles';
import { join } from 'lodash';

export const makePathWithLangAndPlatform = (
  route: Route,
  path: string,
  fallbackLanguage: LanguageCode = DEFAULT_LANGUAGE
) => {
  const platform = route.params.platform || DEFAULT_PLATFORM;
  const lang = route.params.lang || fallbackLanguage;
  const previewVersion = route.params.version;
  const rest = path ?? '';
  if (previewVersion && rest.length > 0) {
    const cleanRest = rest.replace(/^\/+/, '').toLocaleLowerCase().trim();
    if (sectionNames.some((name) => cleanRest.startsWith(name))) {
      return `/${platform}/${lang}/preview/${previewVersion}/${rest}`.replace(
        /\/\//g,
        '/'
      );
    }
  }
  return `/${platform}/${lang}/${rest}`.replace(/\/\//g, '/');
};

export const universalRedirect = (ctx: SsrCtx, path: string) => {
  const newLocation = {
    path: makePathWithLangAndPlatform(ctx.route, path),
    query: ctx.route.query,
  };
  return ctx.redirect(newLocation, 302);
};

export const pushWithLangAndPlatform = (router: VueRouter, path: string) => {
  return router.push(makePathWithLangAndPlatform(router.currentRoute, path));
};

export const replaceWithLangAndPlatform = (router: VueRouter, path: string) => {
  return router.replace(makePathWithLangAndPlatform(router.currentRoute, path));
};

export const parsePath = (
  path: string
): { lang: LanguageCode; platform: 'web' | 'ingame'; rest: string } | null => {
  const matches = /^\/(web|ingame)\/([\w-]{2,5})(\/.+)?/gi.exec(
    path.toLocaleLowerCase()
  );
  if (matches != null && matches.length > 0) {
    return {
      platform: matches[1] as 'web' | 'ingame',
      lang: matches[2],
      rest: matches[3],
    };
  }
  return null;
};
