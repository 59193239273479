




































import { Component, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { AppStore } from '@/themes/v1/stores/app.store';

@Component
export default class Sidemenu extends Vue {
  appModule = getModule(AppStore, this.$store);
  get sections() {
    return this.appModule.sectionsList.filter((x) => !x.hidden && !x.isEmpty);
  }

  onAskQuestionClick() {
    this.appModule.toggleMenu(false);
    this.$track(
      'ask_question',
      'click_button_ask_question',
      this.$route.fullPath
    );
  }
}
