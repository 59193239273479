








import { Component, Vue, Prop } from 'vue-property-decorator';
import StyledSelect from '../components/StyledSelect.vue';

@Component({
  components: {
    StyledSelect,
  },
})
export default class ForeignLinks extends Vue {
  @Prop()
  links!: { uri: string; text: string; type: string }[];

  get selected() {
    return this.links && this.links.length > 0 ? this.links[0].uri : null;
  }

  get options() {
    return this.links.map((link) => ({
      value: link.uri,
      title: link.text,
      icon: link.type,
    }));
  }

  onSelect(link) {
    window.location.href = this.formatLink(link, this.$route);
  }

  private formatLink(link, route) {
    if (link.includes('{location}')) {
      const location = route.path.replace(/^\/[A-Z0-9-]+\/[A-Z0-9-]+/gi, '');
      link = link.replace('{location}', location);
    }
    return link;
  }
}
