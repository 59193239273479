import { AxiosInstance } from 'axios';

import { LanguageCode } from '@/core/system';
import { TicketCreatorOptions } from './creator-options.model';
import {
  CreateTicketCommand,
  CreateTicketResponseViewModel,
} from './create-ticket.model';
import config from '@/env';

export class TicketCreatorService {
  constructor(private readonly $http: AxiosInstance) {}

  async getOptions(language: LanguageCode): Promise<TicketCreatorOptions> {
    const { data } = await this.$http.get<TicketCreatorOptions>(
      `/tickets/creator/options`,
      {
        params: { game: config.ticketsGame, language },
      }
    );
    return data;
  }

  async create(
    command: CreateTicketCommand
  ): Promise<CreateTicketResponseViewModel> {
    const data = new FormData();
    Object.entries(command).forEach((keyValue) => {
      if (keyValue[1] == null) {
        return;
      }
      if (keyValue[1] instanceof Array) {
        for (let i = 0; i < keyValue[1].length; i++) {
          data.append(`${keyValue[0]}`, keyValue[1][i]);
        }
      } else {
        data.append(keyValue[0], keyValue[1]);
      }
    });

    const {
      data: result,
    } = await this.$http.post<CreateTicketResponseViewModel>(
      `/tickets/creator`,
      data,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    );
    return result;
  }
}
