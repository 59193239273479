





























import { Component, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { AppStore } from '@/themes/v1/stores/app.store';
import { DEFAULT_LANGUAGE, DEFAULT_PLATFORM } from '../core/config/defaults';

@Component
export default class Footer extends Vue {
  appModule = getModule(AppStore, this.$store);

  get currentYear() {
    return new Date().getFullYear();
  }

  get socialLinks() {
    return this.appModule?.footerLinks?.socialLinks;
  }

  get textLinks() {
    if (this.appModule?.footerLinks?.additionalLinks == null) {
      return [];
    }
    const platform = this.$route.params.platform || DEFAULT_PLATFORM;
    const language =
      this.$route.params.lang || this.$i18n.locale || DEFAULT_LANGUAGE;
    return this.appModule.footerLinks.additionalLinks.map((link) => ({
      uri: link?.uri
        .replace(/\{platform\}/g, platform)
        .replace(/\{language\}/g, language),
      text: link.text,
    }));
  }
}
