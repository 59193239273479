export type GameId = string;
export type LanguageCode = string;
export type Guid = string;

export interface DeviceInfo {
  isMobile: boolean;
  isMobileOrTablet: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  isIos: boolean;
  isAndroid: boolean;
  isWindows: boolean;
  isMacOS: boolean;
  isDesktopOrTablet: boolean;
}

export interface ErrorInfo {
  status: number;
  message?: string;
  error?: Error;
}

export class ErrorWithStatus extends Error {
  constructor(
    message: string,
    public readonly status: number = 500,
    public readonly details: any = null
  ) {
    super(message);
  }
}
